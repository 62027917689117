import React, { FC, useEffect, useState } from "react";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import DatePicker from "react-datepicker";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";

import { DataGrid, GridCellParams } from "@mui/x-data-grid";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Grid from '@mui/material/Grid2'
import Paper from '@mui/material/Paper'
import { NewEventFormType } from "data/types";
import { uuidv4 } from "@firebase/util";



export interface PageCreateEventProps {}

const PageCreateEvent04: FC<PageCreateEventProps> = () => {

  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const [formType, setFormType] = useState<"user-agreement" | "text" | "options" | "multiple-options">('text')
  const [formOptionText, setFormOptionText] = useState("")
  const [forms, setForms] = useState<NewEventFormType[]>([])
  const [formItem, setFormItem] = useState<NewEventFormType>({
    required: false,
    order: 0,
    options: [],
    valueType: 'text',
    optionType: 'text',
    placeholder: '',
    title: '',
    url: '',
    descriptions: [],
    id: uuidv4(),
  })
  async function resetFormItem(){
    setFormItem({
      required: false,
      order: 0,
      options: [],
      valueType: 'text',
      optionType: 'text',
      placeholder: '',
      title: '',
      url: '',
      descriptions: [],
      id: uuidv4(),
    })
  }

  const removeFormItem = (formId: string) => {
    let newforms = forms.filter((formItm) => {
      return formItm.id != formId
    })
    var returnform: NewEventFormType[] = []
    newforms.forEach((agendItm,index) => {
      let nAgendItm = agendItm
      nAgendItm.order = index
      returnform.push(nAgendItm)
    })
    return returnform
  }

  const formColumn = [
    { field: "id", headerName: "Form ID" },
    { field: "required", headerName: 'Required'},
    { field: "title", headerName: "Name" },
    { field: "placeholder", headerName: "Description" },
    { field: "url", headerName: "Link" },
    { field: "optionType", headerName: "Form Type" },
    { field: "valueType", headerName: "Value" },
    { field: "options", headerName: "Options" },
    { field: "action", headerName: "Actions", renderCell: (params: GridCellParams) => {
      return <div>
        <a onClick={()=>{
          const formId = params.row.id as string
          if (formId != ""){
            const newForms = removeFormItem(formId)
            setForms(newForms)
            const jsonString = JSON.stringify({forms: newForms})
            localStorage.setItem('create_event_forms',jsonString)
          }
          else{

          }
        }} style={{color: 'red'}}>{`Delete`}</a>
        </div>;
    } },
  ]

  useEffect(()=>{
    const eventFormItem = localStorage.getItem('create_event_forms') || '{}'
    if (eventFormItem != '{}'){
      let eventFormD = JSON.parse(eventFormItem) || {forms: []}
      setForms(eventFormD.forms)
    }
  },[])



  const DrawerList = (
    <Box 
      role="presentation" 
      style={{width: '100%'}}
      >
        <div className="relative space-y-8" style={{margin: '16px'}}>
        <h2 className="text-2xl font-semibold">Add {formType == 'options' ? 'Single Choice' : formType == 'multiple-options' ? 'Multiple Choice' : formType == 'text' ? 'Text Based' : formType == 'user-agreement' ? 'User Agreement' : ''} Question</h2>

          <FormItem
              label="Required *"
              desc=""
            >
            <Select defaultValue="-" onChange={(e)=>{
              const sValue = e.target.value
              if (sValue == 'required'){
                setFormItem({...formItem, required: true})
              }
              else{
                setFormItem({...formItem, required: false})
              }
            }}>
              <option value="-">Not Required</option>
              <option value="required">Required</option>
            </Select>
          </FormItem>

          <FormItem
              label="Question Title *"
              desc=""
            >
            <Input type="text" defaultValue={formItem.title} onChange={(e)=>{
              const sValue = e.target.value
              setFormItem({...formItem, title: sValue})
            }}/>
          </FormItem>

          <FormItem
              label="Question Description / Placeholder"
              desc=""
            >
            <Input type="text" defaultValue={formItem.placeholder} onChange={(e)=>{
              const sValue = e.target.value
              setFormItem({...formItem, placeholder: sValue})
            }} />
          </FormItem>

          {(formType == 'user-agreement') && 
          <div>
          <FormItem
          label="Agreement URL"
          desc=""
        >
        <Input type="text" defaultValue={""} />
      </FormItem>
      <br/>
      <FormItem
          label="Agreement Text"
          desc=""
        >
        <Textarea rows={5}/>
      </FormItem>
      </div>}

          

          {(formType == 'text') && 
          <FormItem
          label="Field Type *"
        >
          <Select defaultValue="text" onChange={(e)=>{
            const sVal = e.target.value
            if (sVal == "text"){
              setFormItem({...formItem, valueType: sVal})
            }
            else if (sVal == "email"){
              setFormItem({...formItem, valueType: sVal})
            }
            else if (sVal == "phone"){
              setFormItem({...formItem, valueType: sVal})
            }
            else if (sVal == "number"){
              setFormItem({...formItem, valueType: sVal})
            }
            else{
              setFormItem({...formItem, valueType: sVal})
            }
              
          }}>
          <option value="text">Text</option>
          <option value="email">Email</option>
          <option value="phone">Phone</option>
          <option value="number">Number</option>
        </Select>
      </FormItem>
          }

      {(formType == 'options' || formType == 'multiple-options') && 
           <FormItem
           label="Options *"
           desc=""
         >
           <Input type="text" onChange={(e)=>{
             const changedOption = e.target.value
             setFormOptionText(changedOption)
           }}/>
           <div className="mt-3 flex text-sm text-neutral-6000 dark:text-neutral-300" onClick={()=>{
              let currentOptions = formItem.options
              if (!currentOptions.includes(formOptionText) && formOptionText != ""){
                currentOptions.push(formOptionText)
                setFormOptionText("")
              }
              setFormItem({...formItem, options: currentOptions})
           }}>
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                    >
                      <span>Add Option</span>
                    </label>
            </div>
            <div className="mt-3">
              {
                formItem.options.map((optionItm) => {
                  return <span>{optionItm}<br/></span>
                })
              }
            </div>
           

       </FormItem>
      }

        </div>

        <div className="relative space-y-8" style={{margin: '16px'}}>
        <ButtonPrimary 
        onClick={()=>{
          if (formItem.title != ""){

            var currentForms: NewEventFormType[] = []
            forms.forEach((formItm) => {
              currentForms.push(formItm)
            })
            currentForms.push(formItem)
            setForms(currentForms)
            const jsonString = JSON.stringify({forms: currentForms})
            localStorage.setItem('create_event_forms',jsonString)
            resetFormItem()
            
          }
          setOpen(false)
        }}
        >Add Question</ButtonPrimary>
        <ButtonSecondary className="ml-3" onClick={()=>{
          setOpen(false)
        }}>Cancel</ButtonSecondary>
      </div>

      </Box>
  );

  return (
    <CommonLayout
      index="04"
      backtHref="/create-event-03"
      nextHref="/create-event-05"
    >
      <>
        <h2 className="text-2xl font-semibold">Forms (Optional)</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <FormItem label="Add a question">
            <Select defaultValue="text" onChange={(e) => {
                const c = e.target.value
                if (c == 'options'){
                  setFormType("options")
                }
                if (c == 'multiple-options'){
                  setFormType(c)
                }
                if (c == 'text'){
                  setFormType(c)
                }
                if (c == 'user-agreement'){
                  setFormType(c)
                }
              }}>
              <option value="options">Single Choice - Ask attendee to answer one choice question</option>
              <option value="multiple-options">Multiple Choice - Ask attendees to select multiple answers</option>
              <option value="text">Text Field - Text-based questions</option>
              <option value="user-agreement">User Agreement - Ask attendees to agree with your terms</option>
            </Select>
            <ButtonSecondary className="mt-3" onClick={()=>{
              setFormItem({...formItem, optionType: formType})
              setOpen(true)
            }}>
                Add form question
              </ButtonSecondary>
              <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
          </FormItem>

          <DataGrid  
            sx={{ overflowX: 'scroll' }}
            rows={forms} 
            columns={formColumn} />
          

        </div>


      </>
    </CommonLayout>
  );
};

export default PageCreateEvent04;
