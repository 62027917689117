import React, { FC, useState } from "react";
import ButtonCircle from "shared/Button/ButtonCircle";
import rightImg from "images/SVG-subcribe2.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import Input from "shared/Input/Input";
import Label from "components/Label/Label";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import {contactUs} from "hooks/EventGoAPI"

export interface SectionSubscribe2Props {
  className?: string;
}

const SectionHomeContactus: FC<SectionSubscribe2Props> = ({ className = "" }) => {

  const [contactUsInfo, setContactUsInfo] = useState({
    name: '',
    email: '',
    message: ''
  })
  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`}
      data-nc-id="SectionSubscribe2"
    >

<div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 "  style={{alignItems:'center'}}>
            <div className="max-w-sm space-y-8">
              <div className="flex-grow">
                <NcImage src={rightImg} />
              </div>
            </div>
            <div>
            <h2 className="font-semibold text-3xl sm:text-4xl mt-6 sm:mt-11 justify-start">
              Contact us
            </h2>
              <form className="mt-5 grid grid-cols-1 gap-6">
                <label className="block">
                  <Label>Full name</Label>
                  <Input
                    placeholder="Example Doe"
                    type="text"
                    className="mt-1"
                    onChange={(e)=>{
                      let mess = e.target.value
                      setContactUsInfo({...contactUsInfo, name: mess})
                    }}
                  />
                </label>
                <label className="block">
                  <Label>Email address</Label>

                  <Input
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1"
                    onChange={(e)=>{
                      let mess = e.target.value
                      setContactUsInfo({...contactUsInfo, email: mess})
                    }}
                  />
                </label>
                <label className="block">
                  <Label>Message</Label>

                  <Textarea onChange={(e)=>{
                    let mess = e.target.value
                    setContactUsInfo({...contactUsInfo, message: mess})
                  }} className="mt-1" rows={6} />
                </label>
                
              </form>
              <div className="mt-5">
                  <ButtonPrimary onClick={()=>{
                    if (contactUsInfo.email != "" && contactUsInfo.message != "" && contactUsInfo.name != ""){
                      contactUs(contactUsInfo.email,contactUsInfo.name,contactUsInfo.message)
                      setContactUsInfo({
                        name: '',
                        email: '',
                        message: ''
                      })
                    }
                  }}>Send Message</ButtonPrimary>
                </div>
            </div>
          </div>
        </div>
      

    </div>
    
  );
};

export default SectionHomeContactus;
