import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React, { useState } from "react";
import { Popover } from "@mui/material";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Your account",
    menus: [
      { href: "/signup", label: "Sign Up" },
      { href: "/login", label: "Login" },
      { href: "/help-center", label: "Help center" },
      { href: "/create-event", label: "Create Event" },
    ],
  },
  {
    id: "1",
    title: "Discover",
    menus: [
      {href: '/', label: "For you"},
      {href: '/', label: "Happy Family"},
      {href: '/', label: "Must-see Toronto"},
      {href: '/', label: "Family Friendly"},
      {href: '/', label: "Good For Bounding"},
      {href: '/', label: "Toddlers"},
      {href: '/', label: "Charity"},
    ],
  },
  {
    id: "2",
    title: "Event Go",
    menus: [
      { href: "/about", label: "About" },
      { href: "/news", label: "news" },
      { href: "/sponsorship", label: "Sponsorship" },
      { href: "/contact", label: "Contact us" },
    ],
  },
  {
    id: "3",
    title: "Follow us",
    menus: [
      { href: "https://www.facebook.com/people/EventGo/61554157201368/", label: "Facebook" },
      { href: "https://www.instagram.com/eventgodigital/", label: "Instagram" },
      { href: "https://www.linkedin.com/company/eventgo-digital", label: "LinkedIn" },
      { href: "https://x.com/evtgoto", label: "X" },
      { href: "https://www.xiaohongshu.com/user/profile/649111de000000002b009412", label: "小红书" },
      { href: "#", label: "微信" },
    ],
  },
];

const Footer: React.FC = () => {

  const [menuShow, setMenuShow] = useState({
    showWechat : false,
  })

  function onWechatClick(){
    setMenuShow({showWechat:true})
  }

  function onPopoverClose(){
    setMenuShow({showWechat:false})
  }
  
  const renderWidgetMenuSubItem = (item:any, index: number) => {
    if(item.label === '微信'){
      return (
        <li key={index}>
        <a
          onClick={onWechatClick}
          key={index}
          className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
        >
          {item.label}
        </a>
      </li>
      )
    }else{
      return (
        <li key={index}>
        <a
          key={index}
          className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
          href={item.href}
        >
          {item.label}
        </a>
      </li>
      )
    }
  }
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
      return (
        <div key={index} className="text-sm">
          <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
            {menu.title}
          </h2>
          <ul className="mt-5 space-y-4">
            {menu.menus.map(renderWidgetMenuSubItem)}
          </ul>
        </div>
      ); 
    };

  return (
    <div className="nc-Footer relative py-24 lg:py-28 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 gap-y-3 gap-x-1 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-4 lg:gap-x-1">
        {widgetMenus.map(renderWidgetMenuItem)}
      </div>              
      <div style={{marginTop: 75}}>
      <div className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white text-center"> 
          <a target="_blank" href="/user-terms.html" className="mr-2 text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white text-center">Terms of Service</a>
          <a target="_blank" href="/org-terms.html" className="mr-2 text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white text-center">Terms of Organizer </a>
          <a target="_blank" href="/user-privacy.html" className="mr-2 text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white text-center"> Privacy Policy </a>
          <a target="_blank" href="/cookie-statement.html" className="mr-2 text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white text-center">Cookie Policy </a>
      </div>
      </div>
      <div style={{marginTop: 15}}>
      <div className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white text-center">© {(new Date()).getFullYear()} Copyright  
          <a className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white text-center"> Event Go Digital Inc.</a>
      </div>
      </div>
      <div className="container">
        <Popover
              open={menuShow.showWechat}
              onClose={onPopoverClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
            
            <img src="/wechat.jpg" width={400}/>
        </Popover>
      </div>       
    </div>
  );
};

export default Footer;
