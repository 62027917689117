import React, { FC, Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import GuestsInput from "./HeroSearchForm2Mobile/GuestsInput";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Cropper, {Area } from 'react-easy-crop'
import "react-easy-crop/react-easy-crop.css"

interface ModalSelectImageCropProps {
  renderChildren?: (p: { openModal: () => void }) => React.ReactNode;
}

const TO_RADIANS = Math.PI / 180;

function getRadianAngle(degreeValue:number) {
  return (degreeValue * Math.PI) / 180
}

function rotateSize(width:number, height:number, rotation: number) {
  const rotRad = getRadianAngle(rotation)

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  }
}

async function getCroppedImg(
  imageSrc:string,
  pixelCrop: Area,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) {
  let image = new Image()
  image.src = imageSrc
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return null
  }

  const rotRad = getRadianAngle(rotation)

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  )

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth
  canvas.height = bBoxHeight

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
  ctx.rotate(rotRad)
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
  ctx.translate(-image.width / 2, -image.height / 2)

  // draw rotated image
  ctx.drawImage(image, 0, 0)

  const croppedCanvas = document.createElement('canvas')

  const croppedCtx = croppedCanvas.getContext('2d')

  if (!croppedCtx) {
    return null
  }

  // Set the size of the cropped canvas
  croppedCanvas.width = pixelCrop.width
  croppedCanvas.height = pixelCrop.height

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  )

  // As Base64 string
  // return croppedCanvas.toDataURL('image/jpeg');
  
  // As a blob
  return new Promise((resolve, reject) => {
    croppedCanvas.toBlob((file) => {
      if (file != null){
        resolve(file)
      }
      else{
        resolve("")
      }
    }, 'image/jpeg')
  })
}


const ModalCropImages: FC<ModalSelectImageCropProps> = ({ renderChildren }) => {
  const [showModal, setShowModal] = useState(false);

  const [eventData, setEventData] = useState({
    title: '',
    description: '',
    startDateStr: '',
    endDateStr: '',
    photoURL: '',
    photoURLs: [],
    address: {
      fullAddress: '',
      city: '',
      street: '',
      state: '',
      country: 'Canada',
      postalCode: '',
      name: '',
      unit: '',
      placeId: '',
      lat: 0.0,
      lng: 0.0,
    },
    price: 0,
    tags: [],

  })

  // FOR RESET ALL DATA WHEN CLICK CLEAR BUTTON
  //
  function closeModal() {
    setShowModal(false);
  }

  function openModal() {
    setShowModal(true);
  }

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [tempURL, setTempURL] = useState("")
  const [cropperAreaPixel, setCropperAreaPixel] = useState<Area>({
    width: 0,
    height: 0,
    x:0,
    y:0
  })

  const renderButtonOpenModal = () => {
    return renderChildren ? (
      renderChildren({ openModal })
    ) : (
      <button onClick={openModal}>Select Date</button>
    );
  };

  async function uploadImage(file:File) {
    fetch('https://r2-worker.sam-12e.workers.dev/', {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': file.type
      },
      body: file
    })
      .then((response) => response.text())
      .then((data) => {
        const downloadURL = "https://pub-e888c0363b7c412caf77f22ba84fcb86.r2.dev/" + data
        
        console.log("Download URL: " + downloadURL)
        const eventBasicItem = localStorage.getItem('create_event_basics') || '{}'
        if (eventBasicItem != '{}'){
          let eventBasics = eventData
          eventBasics = JSON.parse(eventBasicItem)
          eventBasics.photoURL = downloadURL
          setEventData({...eventBasics, photoURL: downloadURL})
          localStorage.setItem('create_event_basics',JSON.stringify(eventBasics))
          window.location.reload()
        }
        else{
          let eventBasics = eventData
          eventBasics.photoURL = downloadURL
          localStorage.setItem('create_event_basics',JSON.stringify(eventBasics))
          window.location.reload()
        }
      }) // 
      .catch((error) => console.error(error));
  }

  useEffect(()=>{
      if (localStorage.getItem("photo_temp") != null && localStorage.getItem("photo_temp") != ""){
          setTempURL(localStorage.getItem("photo_temp") || "")
      }
  })

  async function packAndUploadImage(){
    const croppedImage = await getCroppedImg(tempURL,cropperAreaPixel,0) as Blob
    var imageFile = new File([croppedImage],'upload-image')
    uploadImage(imageFile)
  }

  return (
    <>
      {renderButtonOpenModal()}
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-50"
          onClose={closeModal}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <Dialog.Panel className="relative h-full overflow-hidden flex-1 flex flex-col justify-between ">
                  <>
                    <div className="absolute left-4 top-4">
                      <button
                        className="focus:outline-none focus:ring-0"
                        onClick={closeModal}
                      >
                        <XMarkIcon className="w-5 h-5 text-black dark:text-white" />
                      </button>
                    </div>

                    <div className="flex-1 pt-12 p-1 flex flex-col overflow-hidden">
                      <div className="flex-1 flex flex-col overflow-hidden bg-white dark:bg-neutral-800">
                        <div className="flex-1 flex flex-col transition-opacity animate-[myblur_0.4s_ease-in-out] overflow-auto">
                          <div
                            className={`flex-1 relative flex z-10 overflow-hidden`}
                          >
                              <Cropper
                                image={tempURL}
                                crop={crop}
                                zoom={zoom}
                                aspect={4 / 3}
                                onCropChange={setCrop}
                                onCropComplete={(croppedArea, cropperPix) => {
                                  console.log(croppedArea, cropperPix)
                                  setCropperAreaPixel(cropperPix)
                                }}
                                onZoomChange={setZoom}
                            />
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex justify-between">
                      <button
                        type="button"
                        className="underline font-semibold flex-shrink-0"
                        onClick={() => {}}
                      >
                        Cancel
                      </button>
                      <ButtonPrimary
                        sizeClass="px-6 py-3 !rounded-xl"
                        onClick={() => {
                          packAndUploadImage()
                          closeModal();
                        }}
                      >
                        Save and upload
                      </ButtonPrimary>
                    </div>
                  </>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ModalCropImages;
