import React, { FC, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import { auth, fs } from 'firebaseSetup'
import { createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { getDoc, getDocs, collection, doc, setDoc, Timestamp } from "firebase/firestore"
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel } from "@mui/material";

export interface PageSignUpProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Google",
    href: "",
    icon: googleSvg,
  },
];

const afterLoginAction = () => {
  let eventView = {eventId: "", viewId: "", type: ""}
  let eventVStr = localStorage.getItem("login_redirect") || "{}"
  if (eventVStr != "{}"){
    eventView = JSON.parse(eventVStr)
    if (eventView.eventId != ""){
      if (eventView.type == "event"){
        window.location.href = "/event-order/" + eventView.eventId + "/" + eventView.viewId
      }
      else if (eventView.type == "tour"){
        window.location.href = "/tour-order/" + eventView.eventId + "/" + eventView.viewId
      }
      else{
        window.location.href = "/event-order/" + eventView.eventId + "/" + eventView.viewId
      }
    }
    else{
      window.location.href = "/account-orders"
    }
  }
  else{
    window.location.href = "/account-orders"
  }
}

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const [userInfo, setUserInfo] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    profileURL: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/2048px-Default_pfp.svg.png',
  })

  const [agreePrivacy, setAgreePrivacy] = useState({
    agreed:false,
    showPrivacyDialog:false,
  })

  function loginWithGoogle(){
    var provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      'login_hint': 'user@example.com'
    });
    signInWithPopup(auth,provider).then((userVal) => {
      const userUID = userVal.user.uid || ""
      const userEmail = userVal.user.email || ""
      const userDisplayName = userVal.user.displayName || ""
      const userProfile = userVal.user.photoURL || ""
      let fn = ""
      let ln = ""
      if (userDisplayName.includes(" ")){
        fn = userDisplayName.split(" ")[0]
        ln = userDisplayName.split(" ")[1]
      }
      else {
        fn = userDisplayName
        ln = userDisplayName
      }
      localStorage.setItem("user_uid",userUID)
      let doccRef = doc(fs,"users",userUID)
      auth.currentUser?.getIdToken(true).then((idToken)=>{
        if (idToken != ""){
          localStorage.setItem('user_token',idToken)
        }
      })
      getDoc(doccRef).then((snapshot) => {
        if (snapshot.exists()){
          const userData = snapshot.data()
          const userDataString = JSON.stringify({"user": userData})
          localStorage.setItem("user",userDataString)

          if (userData.orgId != null && userData.orgId != ""){
            localStorage.setItem("org_id",userData.orgId)
            if (userData.orgName != null){
              localStorage.setItem("org_name",userData.orgName)
            }
            if (userData.profileURL != null){
              localStorage.setItem("user_profile",userData.profileURL)
            }
          }

          afterLoginAction()
        }
        else{
          //create user
          console.log('no user found')
          let userData = {
            'firstName':fn,
            'lastName':ln,
            'email':userEmail,
            'currentEvent': '',
            'lang': 'en',
            'userName': userDisplayName,
            'name': userDisplayName,
            'profileURL':userProfile,
            'isAnonymous': false,
            'timestamp': Timestamp.fromDate(new Date()),
          }
          setDoc(doc(fs,'users',userUID),userData).then((a)=>{
            localStorage.setItem("user_profile",userProfile)
            const userDataString = JSON.stringify({"user": userData})
            localStorage.setItem("user",userDataString)
            //go to account
            afterLoginAction()
          }).catch((e) => {
            alert(e.message)
          })
          
        }
      })
    
    }).catch((e) => {
      alert('login failed: ')
    })
  }

  async function createUserWithCred(email:string, password:string){
    createUserWithEmailAndPassword(auth,email,password).then((userCred) => {
      const userUID = userCred.user.uid
      localStorage.setItem("user_uid",userUID)
      auth.currentUser?.getIdToken(true).then((idToken)=>{
        if (idToken != ""){
          localStorage.setItem('user_token',idToken)
        }
        let userData = {
          'firstName':userInfo.firstName,
          'lastName':userInfo.lastName,
          'email':email,
          'currentEvent': '',
          'lang': 'en',
          'userName': userInfo.firstName + " " + userInfo.lastName,
          'name': userInfo.firstName + " " + userInfo.lastName,
          'profileURL':userInfo.profileURL,
          'isAnonymous': false,
          'timestamp': Timestamp.fromDate(new Date()),
        }
        setDoc(doc(fs,'users',userUID),userData).then((a)=>{
          localStorage.setItem("user_profile",userInfo.profileURL)
          const userDataString = JSON.stringify({"user": userData})
          localStorage.setItem("user",userDataString)
          //go to account
          afterLoginAction()
        })
      })
    }).catch((e)=>{
      alert("Error: The email is already signed up")
    })
  }
  
  function handleDisagreePrivacy() {
    setAgreePrivacy({agreed:false, showPrivacyDialog:false})
  }

  function handleAgreePrivacy() {
    setAgreePrivacy({agreed:true, showPrivacyDialog:false})
  }

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up || Event Go</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Signup
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                onClick={()=>{
                  loginWithGoogle()
                }}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6">
          <label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">
                First Name
              </span>
              <Input
                type="text"
                placeholder=""
                className="mt-1"
                onChange={(e)=>{
                  const emailA = e.target.value
                  let userIn = userInfo
                  userIn.firstName = emailA
                  setUserInfo(userIn)
                }}
              />
          </label>
          <label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">
                Last Name
              </span>
              <Input
                type="text"
                placeholder=""
                className="mt-1"
                onChange={(e)=>{
                  const emailA = e.target.value
                  let userIn = userInfo
                  userIn.lastName = emailA
                  setUserInfo(userIn)
                }}
              />
          </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                onChange={(e)=>{
                  const emailA = e.target.value
                  let userIn = userInfo
                  userIn.email = emailA
                  setUserInfo(userIn)
                }}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
              </span>
              <Input type="password" className="mt-1" 
              onChange={(e)=>{
                  const pass = e.target.value
                  let userIn = userInfo
                  userIn.password = pass
                  setUserInfo(userIn)
                }}/>
            </label>
            <label className="block">
              <Checkbox className="text-neutral-800 dark:text-neutral-200" checked={agreePrivacy.agreed} defaultChecked={false} onChange={()=>{
                setAgreePrivacy({agreed:!agreePrivacy.agreed, showPrivacyDialog:false})
              }}/>              
            <a target="_blank" href="/user-privacy.html">
              <span className="text-neutral-800 dark:text-neutral-200">agree to《EventGo Privacy Policy》</span>
            </a>    
            </label>            

          </form>
          <div className="flex justify-center items-center max-w-md mx-auto space-y-6">
         
          <ButtonPrimary className="sm:px-20" onClick={()=>{
            if(!agreePrivacy.agreed){
              setAgreePrivacy({agreed:false, showPrivacyDialog:true})
              return
            }
            if (userInfo.email != "" && userInfo.password != ""){
              createUserWithCred(userInfo.email,userInfo.password)
            }
            else{
              alert("You have to enter email and password")
            }
            
          }}>Signup</ButtonPrimary>
          
          </div>
          

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link to="/login">Sign in</Link>
          </span>
        </div>
      </div>
      <Dialog
        open={agreePrivacy.showPrivacyDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Privacy"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You should agree the EventGo Privacy Policy》before you register the account.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisagreePrivacy}>Disagree</Button>
          <Button onClick={handleAgreePrivacy} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PageSignUp;
