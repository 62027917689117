import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import {
  CurrencyDollarIcon,
  CurrencyBangladeshiIcon,
  CurrencyEuroIcon,
  CurrencyPoundIcon,
  CurrencyRupeeIcon,
  BanknotesIcon,
  QrCodeIcon,
  
} from "@heroicons/react/24/outline";
import { Fragment, FC } from "react";
import { QRCodeCanvas } from 'qrcode.react'
import SocialsList from "shared/SocialsList/SocialsList"
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import { RWebShare } from "react-web-share";
import { SocialType } from "shared/SocialsShare/SocialsShare";

export interface CommonEventInfoProps {
    eventId: string;
    eventTitle: string;
  }

  


const EventInfoDropdown: FC<CommonEventInfoProps> = ({ eventId = "", eventTitle = "Event Name Here" }) => {
    const socialsShare: SocialType[] = [
        { name: "Facebook", icon: "lab la-facebook-square", href: `https://evtgo.com/e/${eventId}` },
        { name: "Twitter", icon: "lab la-twitter", href: `https://evtgo.com/e/${eventId}` },
        { name: "Youtube", icon: "lab la-youtube", href: `https://evtgo.com/e/${eventId}` },
        { name: "Instagram", icon: "lab la-instagram", href: `https://evtgo.com/e/${eventId}` },
      ];
  return (
    <div className="">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`
                ${open ? "" : "text-opacity-80"}
                group px-3 py-1.5 border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-gray-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <QrCodeIcon className="w-5 h-5 opacity-80" />
              <span className="ml-2 select-none">Share Event</span>
              <ChevronDownIcon
                className={`${open ? "-rotate-180" : "text-opacity-70"}
                  ml-2 h-4 w-4  group-hover:text-opacity-80 transition ease-in-out duration-150`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-50 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-50 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-[300px] px-4 mt-4 right-0 sm:px-0">
                <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5">
                    <div style={{margin: '25px'}}>
                        <div className="flex justify-center">
                            <span className="text-2xl font-semibold text-center">{eventTitle}</span>
                        </div>
                        <div className="flex justify-center mt-3">
                            <SocialsList socials={socialsShare}/>
                        </div>
                        <div className="flex justify-center mt-3">
                        <QRCodeCanvas
                                    id="qrCode"
                                    value={
                                      "https://evtgo.com/e/" + eventId 
                                    }
                                    size={128}
                                    bgColor={"#ffffff"}
                                    fgColor={"#000000"}
                                    level={"L"}
                                    includeMargin={false}
                                    imageSettings={{
                                      src: '',
                                      height: 20,
                                      width: 20,
                                      excavate: true,
                                    }}
                                  />

                        </div>
                        <div className="flow-root mt-5">
                        <div className="flex text-neutral-700 dark:text-neutral-300 text-sm -mx-3 -my-1.5">
                        <RWebShare
          data={{
            text: ``,
            url: `https://evtgo.com/e/${eventId}`,
            title: `${eventTitle} | Event Go`,
          }}
          onClick={() => {
          }}
        >
  <span className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
              />
            </svg>
            <span className="hidden sm:block ml-2.5">Share</span>
          </span>
          
        </RWebShare>
        <span className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer" onClick={()=>{
            
        }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
              />
            </svg>
            <span className="hidden sm:block ml-2.5">Save</span>
          </span>

          <span className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
              />
            </svg>
            <span className="hidden sm:block ml-2.5">Copy</span>
          </span>
        </div>

                        </div>
                        

                    

                    </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}

export default EventInfoDropdown