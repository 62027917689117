import React, { FC, useState, useEffect } from "react";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { uuidv4 } from "@firebase/util";
import { Link } from "react-router-dom";

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Checkbox from "@mui/material/Checkbox";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { numberLiteralTypeAnnotation } from "@babel/types";
import { NewEventCouponType, NewEventTicketType } from "data/types";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { DataGrid, GridCellParams } from "@mui/x-data-grid";
import Label from "components/Label/Label";
import { Timestamp } from "firebase/firestore";

export interface PageCreateEventProps {}

const PageCreateEvent02: FC<PageCreateEventProps> = () => {

  const [open, setOpen] = useState(false);
  const [couponOpen, setCouponOpen] = useState(false)
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  const toggleCouponDrawer = (newOpen: boolean) => () => {
    setCouponOpen(newOpen)
  }

  const [ticketItem, setTicketItem] = useState<NewEventTicketType>({
    price: 0,
    priceOriginal: 0,
    priceStripe: 0,
    priceService: 0,
    priceDescription: 'General Admission',
    count: 50,
    order: 0,
    min: 1,
    max: 10,
    saleStartStr: '',
    saleEndStr: '',
    absorbFee: false,
    priceCurrency: 'CAD',
    priceType: 'FREE',
    priceId: '',
    id: uuidv4(),
  })

  const [couponItem, setCouponItem] = useState({
    amount: 0,
    eventId: '',
    priceId: '',
    orgId: '',
    promotionCode: '',
    promoType: 'FREE',
    created: Timestamp.fromDate(new Date()),
    code: '',
  })

  const [tickets, setTickets] = useState<NewEventTicketType[]>([])
  const [coupons, setCoupons] = useState<NewEventCouponType[]>([])
  const [feeAbsorb, setFeeAbsorb] = useState<string>('')

  const ticketColumn = [
    { field: "id", flex: 0.3, headerName: "Ticket ID" },
    { field: "priceDescription", headerName: "Ticket Name" },
    { field: "price", headerName: "Price Customer" },
    { field: "count", headerName: "Max Tickets" },
    { field: "action", flex: 1, headerName: "Actions", renderCell: (params: GridCellParams) => {
      return <div>
        <div onClick={()=>{
          
        }} style={{color: 'red'}}>{`Delete`}</div>
        </div>;
    } },
  ]

  function resetTicketItem(absorb: boolean){
    setTicketItem({
      price: 0,
      priceOriginal: 0,
      priceStripe: 0,
      priceService: 0,
      priceDescription: '',
      count: 50,
      order: tickets.length,
      min: 0,
      max: 10,
      saleStartStr: '',
      saleEndStr: '',
      absorbFee: absorb,
      priceCurrency: 'CAD',
      priceType: 'FREE',
      priceId: '',
      id: uuidv4(),
    })
  }

  const [couponType, setCouponType] = useState<string>('')

  const removeTicketItem = (ticketItemId: string) => {
    let newTickets = tickets.filter((tickItm) => {
      return tickItm.id != ticketItemId
    })
    var returnTickets: NewEventTicketType[] = []
    newTickets.forEach((tickItm,index) => {
      let nTicketItm = tickItm
      nTicketItm.order = index
      returnTickets.push(nTicketItm)
    })
    if (returnTickets.length == 0){
      setFeeAbsorb('')
    }
    return returnTickets
  }

  useEffect(()=>{
    const eventTicketsItem = localStorage.getItem('create_event_tickets') || '{}'
    if (eventTicketsItem != '{}'){
      let eventTicketD = JSON.parse(eventTicketsItem) || {prices: []}
      setTickets(eventTicketD.prices)
      console.log(`Loaded tickets: ${tickets.length}`)
    }
    const eventCouponsItem = localStorage.getItem('create_event_coupons') || '{}'
    if (eventCouponsItem != '{}'){
      let eventCouponD = JSON.parse(eventCouponsItem) || {coupons: []}
      setCoupons(eventCouponD.coupons)
      console.log(`Loaded coupon: ${coupons.length}`)
    }
  },[])

  const CouponDrawerList = (
    <Box 
      role="presentation" 
      style={{width: '100%'}}
      >
        <div className="relative space-y-8" style={{margin: '16px'}}>
          <FormItem
              label="Choosing Coupon Type"
              desc={couponType == 'free' ? 'Free coupon allow you to give away tickets for free. Customers dont have to pay' : couponType == 'amount' ? 'You can add One-Time amount to reduce total amount' : couponType == 'percentage' ? 'You can add coupon by percentage to lower amount' : ''}
            >
            <Select defaultValue="-" onChange={(e) => {
                const c = e.target.value
                setCouponType(c)
              }}>
              <option value="-">Select from following options</option>
              <option value="free">Free Ticket - Free Admission</option>
              <option value="percentage">Percentage - Use this to allow percentage discount per order</option>
              <option value="amount">Amount - Use this to set specific amount per order</option>
            </Select>
          </FormItem>

          {
            couponType != 'free' && couponType != '' && couponType != '-' &&
            <FormItem
              label={couponType == 'percentage' ? 'Enter discount percentage' : 'Enter discount amount'}
              desc={couponType == 'free' ? 'You can add free admission ticket' : ''}
            >
            <Input type="number" defaultValue={0} max={couponType == 'percentage' ? 100 : 1000} />
          </FormItem>
          }
          

          <FormItem
              label="Enter coupon code"
              desc=""
            >
            <Input type="text" />
          </FormItem>

        </div>

        <div className="relative space-y-8" style={{margin: '16px'}}>
        <ButtonPrimary 
        disabled={true}
        onClick={()=>{
          setCouponOpen(false)
        }}
        >Add Coupon</ButtonPrimary>
        <ButtonSecondary className="ml-3" onClick={()=>{
          setCouponOpen(false)
        }}>Cancel</ButtonSecondary>
      </div>

      </Box>
  )
  const TicketDrawerList = (
    <Box 
      role="presentation" 
      style={{width: '100%'}}
      >
      <div className="relative space-y-8" style={{margin: '16px'}}>
        <FormItem
            label="Choosing Pricing Tiers"
            desc=""
          >
            <Select defaultValue="free" onChange={(e)=>{
              const planT =e.target.value
              if (planT == 'paid'){
                setTicketItem({...ticketItem, priceType: 'CREDIT'})
              }
              else {
                setTicketItem({...ticketItem, priceType: 'FREE'})
              }
            }}>
              <option value="free">Free Ticket - Free Admission</option>
              <option value="paid">Paid Ticket - Add your pricing</option>
            </Select>
          </FormItem>

          <FormItem
            label="Ticket Name *"
            desc=""
          >
            <Input 
              type="text" 
              placeholder="General Admission" 
              onChange={(e)=>{
                const pText = e.target.value
                setTicketItem({...ticketItem, priceDescription: pText})
              }}
              maxLength={20}/>
          </FormItem>

          <FormItem
            label="Available Quantity *"
            desc=""
          >
            <Input 
              type="number" 
              placeholder="50"
              defaultValue={ticketItem.count}
              onChange={(e)=>{
                let changeV = e.target.value
                let qtyNum = Number(changeV) || 0
                setTicketItem({...ticketItem, count: qtyNum})
              }}/>
          </FormItem>

          <FormItem
            label="Price Currency"
            desc=""
          >
            <Select disabled={ticketItem.priceType == 'FREE'} onChange={(e) => {
              const c = e.target.value
              if (c == 'USD' || c == 'CAD'){
                setTicketItem({...ticketItem, priceCurrency: c})
              }
            }}>
              <option value="CAD">CAD</option>
              <option value="USD">USD</option>
            </Select>
          </FormItem>


          <FormItem
            label="Ticket Price *"
            desc={''}
          >
            <Input 
              disabled={ticketItem.priceType == 'FREE'}
              type="number" 
              placeholder="0.00"
              min={0}
              defaultValue={ticketItem.price}
              onChange={(e)=>{
                let changeV = e.target.value
                let moneyTarget = Number(changeV) || 0
                if (moneyTarget == 0){
                  let serviceFee = 0
                    let stripeFee = 0
                    setTicketItem({...ticketItem, price: 0, priceOriginal: 0, priceStripe: stripeFee, priceService: serviceFee,})

                }
                else{
                  if (ticketItem.absorbFee){
                    let serviceFee = Math.round((moneyTarget * 0.05) * 1.13 * 100) / 100
                    let stripeFee = Math.round((moneyTarget * 0.029 + 0.3) * 1.13 * 100) / 100
  
                    setTicketItem({...ticketItem, price: moneyTarget, priceOriginal: moneyTarget, priceStripe: stripeFee, priceService: serviceFee, priceType: 'CREDIT'})
                  }
                  else{
                    let serviceFee = Math.round((moneyTarget * 0.05) * 1.13 * 100) / 100
                    let stripeFee = Math.round((moneyTarget * 0.029 + 0.3) * 1.13 * 100) / 100
                    let totalAmount = moneyTarget + serviceFee + stripeFee
                    setTicketItem({...ticketItem, price: totalAmount, priceOriginal: moneyTarget, priceStripe: stripeFee, priceService: serviceFee, priceType: 'CREDIT'})
                  }
                }
                
                
              }}/>
               
          </FormItem>
          
          <FormItem label="Absorb Fees">
          <Checkbox defaultChecked={ticketItem.absorbFee} disabled={feeAbsorb != "" || ticketItem.priceType == 'FREE'} onChange={(e)=>{
            let absorbFee = e.target.checked
            
            let moneyTarget = ticketItem.priceOriginal
                if (absorbFee){
                  let serviceFee = Math.round((moneyTarget * 0.05) * 1.13 * 100) / 100
                  let stripeFee = Math.round((moneyTarget * 0.029 + 0.3) * 1.13 * 100) / 100
                  setTicketItem({...ticketItem, absorbFee: absorbFee, price: moneyTarget, priceOriginal: moneyTarget, priceStripe: stripeFee, priceService: serviceFee})
                }
                else{
                  let serviceFee = Math.round((moneyTarget * 0.05) * 1.13 * 100) / 100
                  let stripeFee = Math.round((moneyTarget * 0.029 + 0.3) * 1.13 * 100) / 100
                  let totalAmount = moneyTarget + serviceFee + stripeFee
                  setTicketItem({...ticketItem, absorbFee: absorbFee, price: totalAmount, priceOriginal: moneyTarget, priceStripe: stripeFee, priceService: serviceFee})
                }

            }}/>
          <a>
                <span style={{}}>absorb fees: ticketing fees are deducted from the total</span>
              </a>
          <div>
            <div style={{marginTop: '5px', marginBottom: '5px'}} className="flex justify-between">
              <div>
                <div>Service Fees: </div>
                <div style={{fontWeight: 'lighter'}}>5% per ticket + TAX</div>
              </div>
              <div><b>{ticketItem.priceService.toLocaleString('en-US',{style: "currency", currency: ticketItem.priceCurrency})}</b></div></div>
            <div style={{marginTop: '5px', marginBottom: '5px'}} className="flex justify-between">
              <div>
                <div>Payment Processing Fees: </div>
                <div style={{fontWeight: 'lighter'}}>(2.9% + $0.3) per ticket + TAX</div>
              </div>
              <div><b>{ticketItem.priceStripe.toLocaleString('en-US',{style: "currency", currency: ticketItem.priceCurrency})}</b></div></div>
            <div style={{marginTop: '5px', marginBottom: '5px'}} className="flex justify-between"><div>Buyer Total: </div><div><b>{ticketItem.price.toLocaleString('en-US',{style: "currency", currency: ticketItem.priceCurrency})}</b></div></div>
            <div style={{marginTop: '5px', marginBottom: '5px'}} className="flex justify-between"><div>Your Payout: </div><div><b>{ticketItem.absorbFee ? (ticketItem.price - ticketItem.priceService - ticketItem.priceStripe).toLocaleString('en-US',{style: "currency", currency: ticketItem.priceCurrency}) : ticketItem.priceOriginal.toLocaleString('en-US',{style: "currency", currency: ticketItem.priceCurrency})}</b></div></div>
            </div>
              
          </FormItem>
          

          <FormItem
            label="Sale Start *"
            desc={ticketItem.saleStartStr}
          >
            <Input 
              type="datetime-local"
              onChange={(e)=>{
              const dateSelect = e.target.value
              let dateVal = new Date(dateSelect)
              let now = new Date()
              if (dateVal < now){
                alert('The date can only be in future')
              }
              else{
                setTicketItem({...ticketItem, saleStartStr: dateSelect})
              }
            }}/>

          </FormItem>

          <FormItem
            label="Sale End *"
            desc={ticketItem.saleEndStr}
          >
            <Input type="datetime-local" 
            onChange={(e)=>{
              const dateSelect = e.target.value
              let dateVal = new Date(dateSelect)
              let dateStart = new Date(ticketItem.saleStartStr)
              if (dateStart < dateVal){
                setTicketItem({...ticketItem, saleEndStr: dateSelect})
              }
              else{
                alert("The sale end date must be greater")
              }
              
            }}/>
          </FormItem>

          <FormItem
            label="Tickets Per Order *"
            desc=""
          >
            <NcInputNumber 
              onChange={(value) => {
                setTicketItem({...ticketItem, min: value})
              }}
              max={10}
              min={1}
              defaultValue={ticketItem.min}
              label="Min"
              desc="Number of tickets"/>
            <br/>
            <NcInputNumber 
              onChange={(value) => {
                setTicketItem({...ticketItem, min: value})
              }}
              max={25}
              min={ticketItem.min}
              defaultValue={ticketItem.max}
              label="Max"
              desc="Number of tickets"/>
          </FormItem>
          
      </div>
      <Divider />
      <div className="relative space-y-8" style={{margin: '16px'}}>
        <ButtonPrimary 
        onClick={()=>{
          let today = new Date()
          let startD = new Date(ticketItem.saleStartStr)
          let endD = new Date(ticketItem.saleEndStr)

          if (ticketItem.id != "" && ticketItem.priceDescription != "" && ticketItem.price >= 0 && startD >= today && endD >= startD){
            var currentTickets: NewEventTicketType[] = []
            tickets.forEach((tik) => {
              currentTickets.push(tik)
            })
            currentTickets.push(ticketItem)
            setTickets(currentTickets)
            const jsonString = JSON.stringify({prices: currentTickets})
            localStorage.setItem('create_event_tickets',jsonString)

            if (ticketItem.absorbFee){
              setFeeAbsorb('yes')
            }
            else{
              setFeeAbsorb('no')
            }

            resetTicketItem(feeAbsorb == 'yes')
            setOpen(false)
          }
          else{
            if (ticketItem.priceDescription == ""){
              alert('Please type price name')
            }
            if (ticketItem.price < 0){
              alert("please enter price")
            }
            if (endD < startD || startD < today){
              alert('Sale end date need to be greater than sale start date')
            }
            console.log(ticketItem)
          }
        }}
        >Add Price</ButtonPrimary>
        <ButtonSecondary className="ml-3" onClick={()=>{
          setOpen(false)
        }}>Cancel</ButtonSecondary>
      </div>

    </Box>
  );

  return (
    <CommonLayout
      index="02"
      backtHref="/create-event"
      nextHref="/create-event-03"
    >
      <>
        <h2 className="text-2xl font-semibold">Tickets and Coupons</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}

          <FormItem
            label="Choosing Pricing Tiers"
            desc=""
          >
            <Select>
              <option value="free">Free Ticket - Free Admission</option>
              <option value="paid">Paid Ticket - Add your pricing</option>
            </Select>
          </FormItem>

          <ButtonSecondary className="mt-3" onClick={toggleDrawer(true)}>
              Add Ticket Type
          </ButtonSecondary>
          <Drawer open={open} onClose={toggleDrawer(false)}>
              {TicketDrawerList}
          </Drawer>

          <DataGrid  
            sx={{ overflowX: 'scroll' }}
            rows={tickets} 
            columns={ticketColumn} />

          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {
          tickets.map((ticketItm, index) => {
            return <Paper 
            elevation={1} 
            style={{padding: '25px', marginTop: '15px'}}>
            <h3 style={{fontWeight: 'bold', marginBottom: 5, marginTop: 5}}>[{ticketItm.order+1}] {ticketItm.priceDescription} ({ticketItm.count} Available)</h3>
            <p>Buyer total: <b>{ticketItm.price.toLocaleString('en-US',{style: 'currency', currency: ticketItm.priceCurrency})}</b></p>
            <p>Pay to you: <b>{ticketItm.priceOriginal.toLocaleString('en-US',{style: 'currency', currency: ticketItm.priceCurrency})}</b></p>
            <p>Total Quantity: {ticketItm.count}</p>
            <p>Fee Absorb: <b>{ticketItm.absorbFee ? 'YES' : 'NO'}</b></p>
            <div className="mt-5 flow-root">
              <div className="flex text-neutral-700 dark:text-neutral-300 text-sm -mx-3 -my-1.5">
              {/* <span className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer">
          <span className="hidden sm:block">Modify</span>
        </span> */}

        <span className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer" onClick={()=>{
          let newTickets = removeTicketItem(
            ticketItm.id
          )
          setTickets(newTickets)
          const jsonString = JSON.stringify({prices: newTickets})
          localStorage.setItem('create_event_tickets',jsonString)
          
        }}>
          <span className="hidden sm:block">Delete</span>
        </span>

              </div>
            </div>
          </Paper>
        })
      }
      </List>

      <FormItem
            label="Refund Policy"
            desc="Choose refund policy that is right for you. By default, there's no refund"
          >
            <Select onChange={(e)=>{
              const selectedVal = e.target.value
              if (selectedVal == "no-refund"){
                localStorage.setItem('create_event_refund',selectedVal)
              }
              else if (selectedVal == "full-refund"){
                localStorage.setItem('create_event_refund',selectedVal)
              }
            }}>
              <option value="no-refund">No Refund - Strict no refund rules applied</option>
              <option value="full-refund">Full Refund - Customer get the full return</option>
            </Select>
    </FormItem>


          {/* <FormItem
            label="Coupons"
            desc="You can add coupon based on pricing target"
          >
            <ButtonSecondary className="mt-3" onClick={toggleCouponDrawer(true)}>
              Add Coupon
            </ButtonSecondary>
            <Drawer open={couponOpen} onClose={toggleCouponDrawer(false)}>
              {CouponDrawerList}
            </Drawer>
          </FormItem> */}

        </div>


      </>
    </CommonLayout>
  );
};

export default PageCreateEvent02;
