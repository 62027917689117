import { initializeApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  signOut,
  signInWithEmailAndPassword,
  NextOrObserver,
  User
} from 'firebase/auth';
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
    apiKey: 'AIzaSyA6yHmX0G8C2YZwu25KcYdD_eGL5XGF5WE',
  authDomain: 'blink-574af.firebaseapp.com',
  databaseURL: 'https://blink-574af.firebaseio.com',
  projectId: 'blink-574af',
  storageBucket: 'blink-574af.appspot.com',
  messagingSenderId: '650318705789',
  appId: '1:650318705789:web:4808bef022c48c3977ad14',
  measurementId: 'G-WZWD4YF8KD',
}; //this is where your firebase app values you copied will go

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const fs = getFirestore(app)

export const signInUser = async (
  email: string, 
  password: string
) => {
  if (!email && !password) return;

  return await signInWithEmailAndPassword(auth, email, password)
}

export const userStateListener = (callback:NextOrObserver<User>) => {
  return onAuthStateChanged(auth, callback)
}

export const SignOutUser = async () => await signOut(auth);