import __taxonomies from "./jsons/__taxonomies.json";
import __stayTaxonomies from "./jsons/__stayTaxonomies.json";
import __experiencesTaxonomies from "./jsons/__experiencesTaxonomies.json";
import { TaxonomyType } from "./types";

const DEMO_CATEGORIES: TaxonomyType[] = __taxonomies.map((item) => ({
  ...item,
  taxonomy: "category",
}));

const DEMO_TAGS: TaxonomyType[] = __taxonomies.map((item) => ({
  ...item,
  taxonomy: "tag",
}));

//

const DEMO_STAY_CATEGORIES: TaxonomyType[] = __stayTaxonomies.map((item) => ({
  ...item,
  taxonomy: "category",
  listingType: "stay",
}));
//
const DEMO_EXPERIENCES_CATEGORIES: TaxonomyType[] = __experiencesTaxonomies.map(
  (item) => ({
    ...item,
    taxonomy: "category",
    listingType: "experiences",
  })
);

function convertDataToTaxonomyType(tagList:any, tagNames:any){
  var localTags: TaxonomyType[] = [];
  localTags.push({
    "id":1,
    'tagName':'all',
    "name": 'For You',
    "href": "/?tag=all",
    "count": 0,
    "color": "yellow",
    'taxonomy': "tag",
  })

  var index = 1;
  tagList.forEach((tag:any)=>{
    index = index + 1;
    localTags.push({
      "id":index + 1,
      'tagName':tag,
      "name": tagNames[tag],
      "href": "/?tag=" + tag,
      "count": 0,
      "color": "yellow",
      'taxonomy': "tag",
    })
  });
  return localTags;
}

export {
  DEMO_CATEGORIES,
  DEMO_TAGS,
  DEMO_STAY_CATEGORIES,
  DEMO_EXPERIENCES_CATEGORIES,
  convertDataToTaxonomyType,
};
