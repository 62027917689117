import Label from "./../../components/Label/Label";
import React, { FC, useEffect, useState } from "react";
import Avatar from "./../../shared/Avatar/Avatar";
import ButtonPrimary from "./../../shared/Button/ButtonPrimary";
import Input from "./../../shared/Input/Input";
import Select from "./../../shared/Select/Select";
import Textarea from "./../../shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet-async";
import Grid from '@mui/material/Grid2';
import { Box, Paper, Button, IconButton, Typography, useTheme } from "@mui/material";
import { auth } from "firebaseSetup";
import { EventDataType } from "data/types";

export interface AccountPageProps {
  className?: string;
}

const OrganizerDashboard: FC<AccountPageProps> = ({ className = "" }) => {

  const [eventData, setEventData] = useState<EventDataType[]>([])

  

  useEffect(()=>{
    
  },[])

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event Organizer Dashboard</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Dashboard</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
  {eventData.map((item, index) => (
    <Grid key={index} size={{ xs: 2, sm: 4, md: 4 }}>
      <Paper 
        elevation={3} 
        style={{padding: '25px'}}
        onClick={(e)=>{
          window.location.href = "/manage-event/" + item.id
        }}>
          <Avatar imgUrl={item.photoURL} sizeClass="w-32 h-32"/>
        <h2 style={{fontSize: 20, fontWeight: 'bold'}}>{item.title}</h2>
        <br/>
        <p>{item.startDateString}</p>
        <p>{item.address}</p>
        <br/>
        <p>{item.price.toLocaleString('en-US',{style: 'currency', currency: 'CAD'})}</p>
      </Paper>
    </Grid>
  ))}
</Grid>

        </div>
      </CommonLayout>
    </div>
  );
};

export default OrganizerDashboard;
