import React, { FC } from "react";
import MainNav1 from "./MainNav1";
import MainNav2 from "./MainNav2";
import EventGoUserNav from "./EventGoUserNav";
import EventGoOrganizerNav from "./EventGoOrganizerNav";

export interface HeaderProps {
  navType?: "EventGoUserNav" | "EventGoOrganizerNav" | "EventGoRegularNav";
  className?: string;
}

const Header: FC<HeaderProps> = ({ navType = "EventGoUserNav", className = "" }) => {
  const renderNav = () => {
    switch (navType) {
      case "EventGoUserNav":
        return <MainNav2 />;
      case "EventGoOrganizerNav":
        return <MainNav2 />;
      default:
        return <EventGoUserNav />;
    }
  };

  return (
    <div
      className={`nc-Header sticky top-0 w-full left-0 right-0 z-40 nc-header-bg ${className}`}
    >
      {renderNav()}
    </div>
  );
};

export default Header;
