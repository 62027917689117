import Label from "./../../components/Label/Label";
import React, { FC, useEffect, useState } from "react";
import Avatar from "./../../shared/Avatar/Avatar";
import ButtonPrimary from "./../../shared/Button/ButtonPrimary";
import Input from "./../../shared/Input/Input";
import Select from "./../../shared/Select/Select";
import Textarea from "./../../shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet-async";
import Grid from '@mui/material/Grid2';
import { useParams } from "react-router";
import { Box, Paper, Button, IconButton, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import useEventDashboardLoader from "hooks/useEventDashboardLoader";
import Heading from "components/Heading/Heading";
import WidgetHeading1 from "containers/BlogPage/WidgetHeading1";
import CardCategory1 from "components/CardCategory1/CardCategory1";
import { DEMO_CATEGORIES } from "data/taxonomies";
import { TaxonomyType } from "data/types";

export interface AccountPageProps {
  className?: string;
}

const EventSettings: FC<AccountPageProps> = ({ className = "" }) => {

    const {id} = useParams()
    const [isLoading, setIsLoading] = useState(false)

    const categoriesDemo: TaxonomyType[] = DEMO_CATEGORIES.filter((_, i) => i < 5);

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event Organizer Dashboard</title>
      </Helmet>
      <CommonLayout eventId={id || ""} eventTitle={'Loading...'}>

      <div className="flex flex-col lg:flex-row">
        <div className="w-full space-y-7 mt-24 lg:mt-0 lg:w-1/5 lg:pl-10 xl:pl-0 xl:w-1/3 ">

        <div
      className={`nc-WidgetCategories rounded-3xl  overflow-hidden ${className}`}
      data-nc-id="WidgetCategories"
    >
      
      <div className="flow-root">
        <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
          {categoriesDemo.map((category) => (
            <CardCategory1
              className="p-4 xl:p-5 hover:bg-neutral-200 dark:hover:bg-neutral-700"
              key={category.id}
              taxonomy={category}
              size="normal"
            />
          ))}
        </div>
      </div>
    </div>

          
        </div>
        <div className="w-full lg:w-4/5 xl:w-2/3 xl:pr-14">
          <Heading>Latest Articles 🎈</Heading>
        </div>
        
      </div>
      
        
      </CommonLayout>
    </div>
  );
};

export default EventSettings;
