import Heading from "components/Heading/Heading";
import React from "react";
import NcImage from "shared/NcImage/NcImage";

export interface People {
  id: string;
  name: string;
  job: string;
  avatar: string;
}

const FOUNDER_DEMO: People[] = [
  {
    id: "1",
    name: `Ticketing & Sales`,
    job: "Manage ticketing seamlessly with fast check-in, real-time sales tracking, and smooth distribution for events of any size.    ",
    avatar:'/about/ticket_service.png',
  },
  {
    id: "4",
    name: `Event Launch & Promotion`,
    job: "Maximize your event's reach with targeted campaigns, event invitations, and promotion tools to boost attendance.    ",
    avatar:"/about/promotion.png",
  },
  {
    id: "3",
    name: `Resources Integration`,
    job: "Easily connect with sponsors, exhibitors, and vendors, integrating key resources for a successful and well-supported event.",
    avatar:
      "/about/resource.png",
  },
  {
    id: "2",
    name: `Production`,
    job: "We provide end-to-end support, including photography, video, and AI-powered albums, to capture and enhance every moment of your event.",
    avatar:
      "/about/production.png",
  },
];

const SectionFounder = () => {
  return (
    <div className="nc-SectionFounder relative">
      <Heading
        desc="Streamlined event planning with innovative promotion, resource connectivity, and tech-driven attendee engagement."
      >
        Our Services
      </Heading>
      <div className="grid sm:grid-cols-2 gap-x-5 gap-y-8 lg:grid-cols-4 xl:gap-x-8">
        {FOUNDER_DEMO.map((item) => (
          <div key={item.id} className="max-w-sm">
            <NcImage
              // containerClassName="relative h-0 rounded-xl overflow-hidden"
              // className="absolute inset-0 object-cover"
              src={item.avatar}
              style={{width:95, height:95, justifySelf:'center'}}
            />
            {/* <div>
            <NcImage
              containerClassName=""
              className=""
              width={100}
              height={100}
              src={item.avatar}
            />
            </div> */}
            <h3 className="text-lg font-semibold text-neutral-900 mt-4 md:text-xl dark:text-neutral-200">
              {item.name}
            </h3>
            <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
              {item.job}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionFounder;
