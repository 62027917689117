import React, { FC, useEffect, useState } from "react";
import Heading from "shared/Heading/Heading";
import Nav from "shared/Nav/Nav";
import NavItem from "shared/NavItem/NavItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { ReactNode } from "react";
import { TaxonomyType } from "data/types";
import { convertDataToTaxonomyType } from "data/taxonomies";

export interface HeaderFilterProps {
  tabActive: string;
  tabs: string[];
  heading: ReactNode;
  subHeading?: ReactNode;
  onClickTab: (item: string, tabName:string) => void;
}

const HeaderFilter: FC<HeaderFilterProps> = ({
  tabActive,
  tabs,
  subHeading = "",
  heading = "Latest Articles",
  onClickTab,
}) => {
  const [tabActiveState, setTabActiveState] = useState(tabActive);
  const [tags, setTags] = useState<TaxonomyType[]>([]);

  useEffect(() => {
    setTabActiveState(tabActive);
    updateTagInfo();
  }, [tabActive]);

  const handleClickTab = (item: string) => {
    setTabActiveState(item);
    var find = false;
    tags.forEach((tag)=>{
      if(tag.name === item){
        find = true;
        onClickTab && onClickTab(tag.tagName!, tag.name);
        return;
      }
    });    
    if(!find){
      onClickTab && onClickTab('all', 'For You');
    }
  };

  function updateTagInfo(){
    const value = localStorage.getItem('tagInfo');
    if(value){
      const tagInfo = JSON.parse(value);
      const tagList = tagInfo['tag_list'];
      const tagNames = tagInfo['tag_name'];
      const localTags = convertDataToTaxonomyType(tagList, tagNames);
      setTags(localTags as any);
    }
  }

  return (
    <div className="flex flex-col mb-8 relative">
      <Heading desc={subHeading}>{heading}</Heading>
      <div className="flex items-center justify-between">
        <Nav
          className="sm:space-x-2"
          containerClassName="relative flex w-full overflow-x-auto text-sm md:text-base hiddenScrollbar"
        >
          {tabs.map((item, index) => (
            <NavItem
              key={index}
              isActive={tabActiveState === item}
              onClick={() => handleClickTab(item)}
            >
              {item}
            </NavItem>
          ))}
        </Nav>
      </div>
    </div>
  );
};

export default HeaderFilter;
