import React, { FC, Fragment, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom"
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import StartRating from "components/StartRating/StartRating";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import { Helmet } from "react-helmet-async";
import { EventPriceDataType, EventDataType } from "data/types";
import LikeSaveBtns from "components/LikeSaveBtns";
import { useLocation, useNavigate } from "react-router-dom";
import { Amenities_demos, PHOTOS } from "./constant";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowRightIcon, Squares2X2Icon } from "@heroicons/react/24/outline";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonCircle from "shared/Button/ButtonCircle";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import EventDetailPagetLayout from "../ListingDetailPage/EventDetailPageLayout";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { RWebShare } from "react-web-share";
import { auth, fs } from "firebaseSetup"
import { AlbumOutlined, CollectionsOutlined, PhotoAlbumRounded } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { updateEventAlbum } from "store";


const MutipleImageGallery = ({ mainImage = '', topImage = '', bottomImage = '', showMoreIcon=false }) => {
  return (
    <div className="relative grid grid-cols-4 gap-1 sm:gap-2 w-full rounded-md sm:rounded-xl h-full">
      <div className="col-span-3 rounded-md sm:rounded-xl overflow-hidden bg-white-200">
        <img className="object-fill rounded-md sm:rounded-xl w-full h-full" src={mainImage} alt="Main" />
      </div>
      <div className="col-span-1 grid grid-rows-5 gap-1 overflow-hidden">
        <div className="row-span-3 rounded-md sm:rounded-xl bg-white-200 overflow-hidden">
          <img className="object-fill rounded-md sm:rounded-xl w-full h-full" src={topImage} alt="Top" />
        </div>
        <div className="relative row-span-2 rounded-md sm:rounded-xl bg-white-200 overflow-hidden">
          <img className="object-fill rounded-md sm:rounded-xl w-full h-full" src={bottomImage} alt="Bottom" />
          {showMoreIcon?<div className="absolute bottom-2 right-2 text-white text-xl">
            <CollectionsOutlined className="w-10 h-10 lg:w-14 lg:h-14"></CollectionsOutlined>
          </div>:<div></div>}
          
        </div>
      </div>
    </div>
  );
};


const EventDetailPageContainer: FC<{}> = () => {
  //

  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);

  let [eventPrices, setEventPrices] = useState<EventPriceDataType[]>()
  const [eventData, setEventData] = useState({
    title: 'Loading Event...',
    description: '',
    descriptions: {
      en: [],
      zh: [],
    },
    startDateString: '',
    endDateString: '',
    id: '',
    orgId: '',
    agenda: [],
    address: {
      name: '',
      fullAddress: '',
      street: '',
      city: '',
      state: '',
      country: '',
      zipCode: '',
      unit: '',
      online: false,
      lat: '0.0',
      lng: '0.0'
    },
    price: 0,
    prices: {},
    photoURL: '',
    location: '',
    photoURLs: [],
    priceDiscount: null,
    type: 'event',
    refundPolicy: "No Cancellation",
    tags: [''],
    shareURL: '',
    terms: {
      text: '',
      texts: [''],
    },
    user: {
      userName: '',
      userOrg: '',
      uid: '',
      email: '',
      imageURL: '',
    }
  })

  const thisPathname = useLocation().pathname;
  const router = useNavigate();

  const { id } = useParams()
  const [searchParams] = useSearchParams();
  const couponCode = searchParams.get('couponCode') || "";
  
  const [eventViewId, setEventViewId] = useState('')
  const [eventId, setEventId] = useState(id || "")
  const [eventCart, setEventCart] = useState<EventPriceDataType[]>([])
  const [shoppingCart, setShoppingCart] = useState({})
  const [shoppingCartRuleAppliedPriceIds, setShoppingCartRuleAppliedPriceIds] = useState([])

  const dispatch = useDispatch()

  const [userCoupon, setUserCoupon] = useState({
    coupon: '',
    couponType: '',
    promotionCode: '',
    amount: 0,
  })

  async function eventPromoValidation(couponCode:string){
    const dataBody = `coupon=${couponCode}&event_id=${id || ''}`;
    fetch("https://v1eventsapiapplycoupon-650318705789.us-central1.run.app?"+dataBody,{
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*', }
    }).then((response) => {
      response.json().then((data) => {
        var dataS = {
          success: false, 
          couponType: "",
          promotionCode: '',
          couponCode: '',
          amount: 0}
        dataS = data
        if (dataS.success){
          if (dataS.couponType == 'FREE'){
            //FREE Order
            setUserCoupon({
              coupon: couponCode,
              couponType: dataS.couponType,
              promotionCode: '',
              amount: 0,
            })
          }
          else if (dataS.couponType == "PERCENTAGE"){
            setUserCoupon({
              coupon: couponCode,
              couponType: dataS.couponType,
              promotionCode: dataS.promotionCode,
              amount: dataS.amount,
            })
          }
          else if (dataS.couponType == "AMOUNT"){
            setUserCoupon({
              coupon: couponCode,
              couponType: dataS.couponType,
              promotionCode: dataS.promotionCode,
              amount: dataS.amount,
            })
          }
          else{
            setUserCoupon({
              coupon: "",
              couponType: "",
              promotionCode: "",
              amount: 0,
            })
            window.alert('Coupon is invalid')
          }
        }
        else{
          setUserCoupon({
            coupon: "",
            couponType: "",
            promotionCode: "",
            amount: 0,
          })
        }
      })
    })
  }


  const fetchEventDetails = () => {
    const eventId = id || ""
    fetch("https://us-central1-blink-574af.cloudfunctions.net/v1EventsAPIFetchEventDetails?event_id="+eventId,{
      headers: {
        "Content-Type": "application/json",
      },

    }).then((response) => {
      const responseTask = response.json();
      responseTask.then((data) => {
        let dataItm = {
          event: eventData,
          viewId: "",
          tickets: {},
          organizer: {}, 
          status: "failure"}
          
          dataItm = data
          if (dataItm.status == 'success'){
            const eventItm = dataItm.event
            var eventPhotoURLs
            if (eventItm.photoURLs.length == 0){
              eventPhotoURLs = [eventItm.photoURL]
            }else{
              eventPhotoURLs = eventItm.photoURLs
            }
            let refund = ""
            if (eventItm.refundPolicy != null){
              refund = eventItm.refundPolicy
            }
            let tags:string[] = []
            if (eventItm.tags != null){
              tags = eventItm.tags
            }
            if (eventItm.prices != null){
              let pricesArray: EventPriceDataType[] = []
              pricesArray = Object.values(eventItm.prices)
              pricesArray = pricesArray.filter((priceItm)=>{
                let endSeconds = priceItm.saleEnd._seconds             
                let endD = new Date(0)
                endD.setUTCSeconds(endSeconds)
                let today = new Date()
                if (endD > today){
                  return true
                }
                else{
                  return false
                }
              })
              pricesArray.sort((a,b)=>{
                return a.order - b.order
              })
              setEventPrices(pricesArray)
            }

            let terms = {text: '', texts: ['']}
            let shareURL = window.location.origin + '/e/' + eventId
            if (eventItm.terms != null){
              terms = eventItm.terms
            }
            if (eventItm.shareURL != null){
              if (eventItm.shareURL != ""){
                shareURL = eventItm.shareURL
              }
            }
            setEventData({...eventItm, refundPolicy: refund, tags: tags, terms: terms, shareURL: shareURL})
            dispatch(updateEventAlbum(eventPhotoURLs))
            
  
            if (dataItm.viewId != ""){
              setEventViewId(dataItm.viewId)
            }

            //apply coupon
            if (couponCode != ""){
              eventPromoValidation(couponCode)
            }
          }
          else{
            window.location.assign('/')
          }
          
      })
    })

  }

  useEffect(()=>{
    fetchEventDetails()
  },[])

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const handleOpenModalImageGallery = () => {
    router(`${thisPathname}/?modal=PHOTO_TOUR_SCROLLABLE`);
  };

  const getTicketAction = () => {
    const eventView = JSON.stringify({"eventId": (id || ""), "viewId": eventViewId, "coupon": couponCode, "type": eventData.type})
    localStorage.setItem("eventView",eventView)
    let redirectLink = ""
    if (eventData.shareURL != ""){
      if (eventData.shareURL.includes('evtgo.com')){
        //go to link
      }
      else if (eventData.shareURL.includes('VIEWONLY')){
        //go to link
        redirectLink = 'VIEWONLY'
      }
      else {
        redirectLink = eventData.shareURL
      }
    }
    if (redirectLink == ""){
      //go to our checkout
      if (auth.currentUser != null){
        if (eventData.type == "tour"){
          window.location.assign("/tour-order/"+ (id || "") + "/" + eventViewId)
        }
        else{
          window.location.assign("/event-order/"+ (id || "") + "/" + eventViewId)
        }
      }
      else{
        const eventRedirection = JSON.stringify({"eventId": (id || ""), "viewId": eventViewId, "coupon": couponCode, "type": eventData.type})
        localStorage.setItem("login_redirect",eventRedirection)
        window.location.assign('/login')
      }
    }
    else if (redirectLink == "VIEWONLY"){

    }
    else{
      //redirect to other platform
      window.location.assign(redirectLink)
    }
    
    
  }

  const renderBadges = () => {
    return <div className="flow-root">
      {

      }
      <Badge name="Just Added" />
    </div>
  }

  const renderActionButtons = () => {
    return (
      <div className="flow-root">
        <div className="flex text-neutral-700 dark:text-neutral-300 text-sm -mx-3 -my-1.5">
        <RWebShare
          data={{
            text: `${eventData.description}`,
            url: `https://evtgo.com/e/${id || ""}`,
            title: `${eventData.title} | Event Go`,
          }}
          onClick={() => {
          }}
        >
  <span className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
              />
            </svg>
            <span className="hidden sm:block ml-2.5">Share</span>
          </span>
          
        </RWebShare>
          
          <span className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
              />
            </svg>
            <span className="hidden sm:block ml-2.5">Save</span>
          </span>
        </div>
      </div>
    );
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          {renderBadges()}
          {renderActionButtons()}
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {eventData.title}
        </h2>

        {/* 3 */}
        <div className="">
        <span>
            <i className="las la-clock"></i>
            <span className="ml-1"> {eventData.startDateString}</span>
          </span>
          <br/>
          <br/>
          <span className="">
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> {eventData.address.fullAddress}</span>
          </span>
        </div>

        {/* 4 */}
        <div className="flex items-center">
          <Avatar 
          hasChecked 
          imgUrl={eventData.user.imageURL}
          sizeClass="h-10 w-10" radius="rounded-full" />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            Hosted by{" "}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              {eventData.user.userName}
            </span>
          </span>
        </div>

        {/* 5 */}
        {/* <div className="w-full border-b border-neutral-100 dark:border-neutral-700" /> */}

        {/* 6 */}
        {/* <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3 ">
            <i className=" las la-user text-2xl "></i>
            <span className="">
              6 <span className="hidden sm:inline-block">guests</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bed text-2xl"></i>
            <span className=" ">
              6 <span className="hidden sm:inline-block">beds</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bath text-2xl"></i>
            <span className=" ">
              3 <span className="hidden sm:inline-block">baths</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-door-open text-2xl"></i>
            <span className=" ">
              2 <span className="hidden sm:inline-block">bedrooms</span>
            </span>
          </div>
        </div> */}
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Event Description</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          {
            eventData.descriptions.en.map((itm,index) => {
              return <div>
              <span>
              {itm}
              </span>
              <br />
              <br />
              </div>
            })
          }
          {/* <span>
            Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides
            accommodation, an outdoor swimming pool, a bar, a shared lounge, a
            garden and barbecue facilities. Complimentary WiFi is provided.
          </span>
          <br />
          <br />
          <span>
            There is a private bathroom with bidet in all units, along with a
            hairdryer and free toiletries.
          </span>
          <br /> <br />
          <span>
            The Symphony 9 Tam Coc offers a terrace. Both a bicycle rental
            service and a car rental service are available at the accommodation,
            while cycling can be enjoyed nearby.
          </span> */}
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Amenities </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {` About the property's amenities and services`}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {Amenities_demos.filter((_, i) => i < 12).map((item) => (
            <div key={item.name} className="flex items-center space-x-3">
              <i className={`text-3xl las ${item.icon}`}></i>
              <span className=" ">{item.name}</span>
            </div>
          ))}
        </div>

        {/* ----- */}
        <div className="w-14 border-b border-neutral-200"></div>
        <div>
          <ButtonSecondary onClick={openModalAmenities}>
            View more 20 amenities
          </ButtonSecondary>
        </div>
        {renderMotalAmenities()}
      </div>
    );
  };

  const renderMotalAmenities = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-4xl">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Amenities
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {Amenities_demos.filter((_, i) => i < 1212).map((item) => (
                      <div
                        key={item.name}
                        className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <i
                          className={`text-4xl text-neutral-6000 las ${item.icon}`}
                        ></i>
                        <span>{item.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSection4 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Ticket Prices </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            View all available ticket prices here
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
            {
              eventPrices?.map((priceItm,index) =>{
                return <div className={index % 2 == 0 ? "p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg" : "p-4  flex justify-between items-center space-x-4 rounded-lg"}>
                <div>
                  <span>{priceItm.priceDescription}</span>
                </div>
                <span>{priceItm.price == 0 ? 'FREE' : priceItm.price.toLocaleString('en-US',{style: 'currency', currency: 'CAD'})}</span>
              </div>
              })
            }
          </div>
        </div>
      </div>
    );
  };

  const renderSectionAgenda = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Agenda </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
            {
              eventData.agenda.map((agendaItm,index) =>{
                return <div className={index % 2 == 0 ? "p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg" : "p-4  flex justify-between items-center space-x-4 rounded-lg"}>
                <span>{'a'}</span>
                <span>{'a'}</span>
              </div>
              })
            }
          </div>
        </div>
      </div>
    );
  };

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Host Information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        <div className="flex items-center space-x-4">
          <Avatar
            hasChecked
            hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
            sizeClass="h-14 w-14"
            radius="rounded-full"
            imgUrl={eventData.user.imageURL}
          />
          <div>
            <a className="block text-xl font-medium" href="##">
              {eventData.user.userName}
            </a>
            <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
              {/* <StartRating /> */}
              {/* <span className="mx-2">·</span> */}
              <span> Regular Host</span>
            </div>
          </div>
        </div>

        {/* desc */}
        <span className="block text-neutral-6000 dark:text-neutral-300">
          {
            eventData.user.userName
          }
        </span>

        {/* info */}
        <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>{eventData.address.city}</span>
          </div>
          {/* <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>Response rate - 100%</span>
          </div> */}
          {/* <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <span>Fast response - within a few hours</span>
          </div> */}
        </div>

        {/* == */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <ButtonSecondary href={`/organizer/${eventData.orgId}`}>See host profile</ButtonSecondary>
        </div>
      </div>
    );
  };

  const handleImageClick = ()=>{
    router('/e/'+id+'?modal=event-collections')
  }

  const renderSectionDate = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Date</h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {eventData.startDateString}
        </span>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Event Start: {eventData.startDateString}
        </span>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Event End: {eventData.endDateString}
        </span>
      </div>
    )
  }

  const renderSection6 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Agenda (23 agendas)</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* Content */}
        {/* <div className="space-y-5">
          <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" />
          <div className="relative">
            <Input
              fontClass=""
              sizeClass="h-16 px-4 py-3"
              rounded="rounded-3xl"
              placeholder="Share your thoughts ..."
            />
            <ButtonCircle
              className="absolute right-2 top-1/2 transform -translate-y-1/2"
              size=" w-12 h-12 "
            >
              <ArrowRightIcon className="w-5 h-5" />
            </ButtonCircle>
          </div>
        </div> */}

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <div className="pt-8">
            <ButtonSecondary>View more</ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };

  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {eventData.address.fullAddress}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
          <div className="rounded-xl overflow-hidden z-0">
            <iframe
              title="x"
              width="100%"
              height="100%"
              loading="lazy"
              allowFullScreen
              referrerPolicy="no-referrer-when-downgrade"
              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAGVJfZMAKYfZ71nzL_v5i3LjTTWnCYwTY&q=${eventData.address.fullAddress.replace(' ','+')}`}
            ></iframe>
          </div>
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Things to know before you buy tickets</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h3 className="text-lg font-semibold">Cancellation policy</h3>
          <div className="mt-3 text-red-500 dark:text-red-400 font-bold">Final Sale, No Cancellation</div>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            {eventData.refundPolicy}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        <div>
          <h3 className="text-lg font-semibold">Terms and Conditions</h3>
          {
            eventData.terms.texts.map((text) => {
              return <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
              {text}
            </span>
            })
          }
        </div>
      
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            {'Ticket Types'}
          </span>
        </div>
        {/* SUM */}
        <div className="flex flex-col space-y-4">
          {
              
            eventPrices?.map((ticketItm) => {
              return <div style={{marginTop: 15}}>
                 <div className="flex justify-between font-semibold">
            <span>{ticketItm.priceDescription}</span>
            <span className="ml-3">{ticketItm.price.toLocaleString("en-US",{style: "currency", currency: ticketItm.priceCurrency})}</span>
          </div>
              </div>
            })
          }
          
        </div>
        {
          userCoupon.coupon != "" &&
          <div className="flex flex-col space-y-4 text-color-red">
        <div>
          <div className="flex justify-between font-semibold">
            <span>Coupon Applied</span>
            <span>{userCoupon.coupon}</span>
          </div>
        </div>
        </div>
        }
        

        {/* SUBMIT */}
        
        <ButtonPrimary disabled={eventData.shareURL == 'VIEWONLY'} onClick={()=>{
            getTicketAction()
        }}>Get Tickets</ButtonPrimary>
        
      </div>
    );
  };

  const renderingMobileFooterLayout = (eventName: string, eventPrice: string, viewId: string, eventId:string) => {
    return (
      <div className="block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-40">
        <div className="container flex items-center justify-between">
          <div className="">
            <span className="block text-xl font-semibold">
              <span className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">
                from
              </span>
              {` `}
              {eventPrice}
            </span>
            <span className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">
              {eventName}
            </span>
            
          </div>
          <div className="ml-2">
          <ButtonPrimary
                sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
                disabled={eventData.shareURL == 'VIEWONLY'}
                onClick={()=>{
                  getTicketAction()
                }}
              >
                Get Tickets
              </ButtonPrimary>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="nc-ListingStayDetailPage">
      {/*  HEADER */}
      <Helmet>
        <title>{eventData.title} | Event Go</title>
      </Helmet>
      
      <header className="rounded-md sm:rounded-xl">
        <div className="relative grid grid-cols-4 gap-1 sm:gap-2 aspect-[2/1] w-full">
          <div
            className="col-span-4 rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
            onClick={handleImageClick}
          >
            {eventData.photoURLs.length > 2
            ?<MutipleImageGallery 
              mainImage={eventData.photoURLs[0]} 
              topImage={eventData.photoURLs[1]} 
              bottomImage={eventData.photoURLs[2]}
              showMoreIcon={eventData.photoURLs.length > 3}></MutipleImageGallery>
            :<img
              src={eventData.photoURL}
              alt={eventData.title}
              style={{objectPosition: 'top'}}
              className="absolute inset-0 object-fill rounded-md sm:rounded-xl w-full h-full"
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
            />}            
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>
          {/*  */}
          {/* <div
            className="col-span-1 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
          >
            <img
              className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
              src={PHOTOS[1]}
              alt="1"
              sizes="400px"
            />
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div> */}

          {/*  */}
          

          
        </div>
      </header>

      {/* MAIN */}
      <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {renderSection1()}
          {renderSection2()}
          {eventData.agenda.length > 0 && renderSectionAgenda()}
          {renderSection4()}
          {/* {renderSection3()}
          {renderSection4()} */}
          {/* <SectionDateRange /> */}
          {renderSection5()}
          {/* {renderSection6()} */}
          {renderSection8()}
        </div>

        {/* SIDEBAR */}
        <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-28">{renderSidebar()}</div>
        </div>
      </main>
      <footer>
        {renderingMobileFooterLayout(eventData.title,eventData.price.toLocaleString('en-US',{style: 'currency', currency: 'CAD'}), eventViewId, id || "")}
      </footer>
    </div>
  );
};


export default function EventDetailPage() {
  return (
    <EventDetailPagetLayout>
      <EventDetailPageContainer />
    </EventDetailPagetLayout>
  );
}
