import React, { FC, useState, useRef, useEffect } from "react";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { MapPinIcon } from "@heroicons/react/24/solid";
import { Map, Marker } from "@vis.gl/react-google-maps";
import Label from "components/Label/Label";
import DatePicker from "react-datepicker";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import Cropper from 'react-easy-crop'
import "react-easy-crop/react-easy-crop.css"
import ModalCropImages from "components/ModalCropImages";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import Autocomplete from "react-google-autocomplete";

import {
  setKey,
  setDefaults,
  setLanguage,
  setRegion,
  fromAddress,
  fromLatLng,
  fromPlaceId,
  setLocationType,
  geocode,
  RequestType,
} from "react-geocode";

export interface PageCreateEventProps {}

const PageCreateEvent01: FC<PageCreateEventProps> = () => {

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)


  const [eventValidation, setEventValidation] = useState({
    title: '',
    description: '',
    startDateStr: '',
    endDateStr: '',
    photoURL: '',
    address: '',
  })
  const [eventData, setEventData] = useState({
    title: '',
    description: '',
    startDateStr: '',
    endDateStr: '',
    photoURL: '',
    photoURLs: [],
    address: {
      fullAddress: '',
      city: '',
      street: '',
      state: '',
      country: 'Canada',
      postalCode: '',
      name: '',
      unit: '',
      placeId: '',
      lat: 0.0,
      lng: 0.0,
    },
    price: 0,
    tags: [],

  })

  useEffect(()=>{
    //getting event data from local
    console.log("loaded first part")
    const eventBasicItem = localStorage.getItem('create_event_basics') || '{}'
    if (eventBasicItem != '{}'){
      setEventData(JSON.parse(eventBasicItem) || {})
    }

  },[])

  async function uploadImage(file:File) {
    fetch('https://r2-worker.sam-12e.workers.dev/', {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': file.type
      },
      body: file
    })
      .then((response) => response.text())
      .then((data) => {
        const downloadURL = "https://pub-e888c0363b7c412caf77f22ba84fcb86.r2.dev/" + data
        let modifyEve = eventData
        modifyEve.photoURL = downloadURL
        setEventData({...eventData, photoURL: downloadURL})
        localStorage.setItem('create_event_basics',JSON.stringify(modifyEve))
      }) // 
      .catch((error) => console.error(error));
  }


  async function geoCodeAddress(fAddress: string) {
    fromAddress(fAddress,process.env.REACT_APP_FIREBASE_API_KEY || '').then((response) => {
      
      const { lat, lng } = response.results[0].geometry.location;
      const place = response.results[0]
      const placeAddress = place.formatted_address
      const placeId = place.place_id

      var city = "";
      var state = "";
      var country = "";
      var zipCode = "";
      var street_address = "";
                    for (
                      let i = 0;
                      i < response.results[0].address_components.length;
                      i++
                    ) {
                      for (
                        let j = 0;
                        j <
                        response.results[0].address_components[i].types.length;
                        j++
                      ) {
                        switch (
                          response.results[0].address_components[i].types[j]
                        ) {
                          case "locality":
                            city =
                              response.results[0].address_components[i]
                                .long_name;
                            break;
                          case "administrative_area_level_1":
                            state =
                              response.results[0].address_components[i]
                                .long_name;
                            break;
                          case "country":
                            country =
                              response.results[0].address_components[i]
                                .long_name;
                            break;
                          case "postal_code":
                            zipCode =
                              response.results[0].address_components[i]
                                .long_name;
                            break;
                          case "street_address":
                            street_address =
                              response.results[0].address_components[i]
                                .long_name;
                            break;
                          default:
                            break;
                        }
                      }
                    }


    const addrConfirm = window.confirm('Are you adding address ' + placeAddress + " ?")
    if (addrConfirm){
      //add this address
      setEventData(
      {...eventData,
      address: {
        fullAddress: placeAddress,
        city: city,
        street: placeAddress,
        state: state,
        country: country,
        postalCode: zipCode,
        name: placeAddress,
        unit: '',
        placeId: placeId,
        lat: lat,
        lng: lng,
      }})
      localStorage.setItem('create_event_basics',JSON.stringify(eventData))
      
    }


    }).catch((e) => {
      alert('Address could not be found')
    })
  }


  function changedToEventItem(item: string, changedTo: string){
    if (item == 'title'){
      setEventData({...eventData, title: changedTo})
    }
    else if (item == 'description'){
      setEventData({...eventData, description: changedTo})
    }
    else if (item == 'startDateStr'){
      setEventData({...eventData, startDateStr: changedTo})
    }
    else if (item == 'endDateStr'){
      setEventData({...eventData, endDateStr: changedTo})
    }

    localStorage.setItem('create_event_basics',JSON.stringify(eventData))
  }

  return (
    <CommonLayout
      index="01"
      backtHref="/create-event"
      nextHref="/create-event-02"
    >
      <>
        <h2 className="text-2xl font-semibold">Enter Basic Information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <FormItem
            label="Event Name *"
            desc="Choose a name for your wonderful event, 50 characters or less"
          >
            <Input 
              placeholder="Sunday morning running meetup" 
              maxLength={50}
              defaultValue={eventData.title} 
              onChange={(e)=>{
                const changedTo = e.target.value
                setEventData({...eventData, title: changedTo})
                localStorage.setItem('create_event_basics',JSON.stringify(eventData))
              }}/>
          </FormItem>

          <FormItem
            label="Event Description *"
            desc="Summarize your event, you can write a short description for your event. Please make it less than 140 characters"
          >
            <Textarea 
              placeholder="Short but procise" 
              rows={6} 
              maxLength={140} 
              defaultValue={eventData.description}
              onChange={(e)=>{
                const changedTo = e.target.value
                setEventData({...eventData, description: changedTo})
                localStorage.setItem('create_event_basics',JSON.stringify(eventData))
              }}/>
          </FormItem>


        </div>

        <div>
          <h2 className="text-2xl font-semibold">Date</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        </div>

        <div className="space-y-8">

        <FormItem
            label="Event Start Date *"
            desc={eventData.startDateStr.includes('T') ? eventData.startDateStr.replace('T', ' ') : eventValidation.startDateStr}
          >
            <Input 
              type="datetime-local" 
              defaultValue={eventData.startDateStr}
              min={(new Date()).toLocaleString('en-US')}
              onChange={(e)=>{
                const changedTo = e.target.value
                let dateSelect = new Date(changedTo)
                let today = new Date()
                if (dateSelect >= today){
                  setEventData({...eventData, startDateStr: changedTo})
                  localStorage.setItem('create_event_basics',JSON.stringify(eventData))
                  setEventValidation({...eventValidation, startDateStr: ''})
                }
                else{
                  setEventValidation({...eventValidation, startDateStr: 'You must select future date'})
                }
                
              }} />
          </FormItem>

          <FormItem
            label="Event End Date *"
            desc={eventData.endDateStr.includes('T') ? eventData.endDateStr.replace('T', ' ') : eventValidation.endDateStr}
          >
            <Input 
              type="datetime-local" 
              min={eventData.startDateStr != '' ? eventData.startDateStr : (new Date()).toLocaleString('en-US')}
              defaultValue={eventData.endDateStr}
              onChange={(e)=>{
                const changedTo = e.target.value
                let dateSelect = new Date(changedTo)
                let dateStart = new Date(eventData.startDateStr)
                if (dateSelect > dateStart){
                  setEventData({...eventData, endDateStr: changedTo})
                  localStorage.setItem('create_event_basics',JSON.stringify(eventData))
                  setEventValidation({...eventValidation, endDateStr: ''})
                }
                else{
                  setEventValidation({...eventValidation, endDateStr: 'You must select future date'})
                }
              }} />
          </FormItem>

        </div>

        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        </div>

        <div className="space-y-8">

        
          <FormItem label="Country">
            <Select onChange={(e)=>{
              const selectedVal = e.target.value
              if (selectedVal == 'canada'){
                setEventData({...eventData, address: {...eventData.address, country: "Canada"}})
                localStorage.setItem('create_event_basics',JSON.stringify(eventData))
              }
              if (selectedVal == 'usa'){
                setEventData({...eventData, address: {...eventData.address, country: "USA"}})
                localStorage.setItem('create_event_basics',JSON.stringify(eventData))
              }
              if (selectedVal == 'china'){
                setEventData({...eventData, address: {...eventData.address, country: "China"}})
                localStorage.setItem('create_event_basics',JSON.stringify(eventData))
              }
            }}>
              <option value="canada">Canada</option>
              <option value="usa">United States</option>
              <option value="china">China</option>
            </Select>
          </FormItem>
          <FormItem label="Street">
            <Input 
              placeholder="xxxx street" 
              defaultValue={eventData.address.street}
              onChange={(e)=>{
                const streetAddr = e.target.value
                setEventData({...eventData, address: {...eventData.address, street: streetAddr}})
                localStorage.setItem('create_event_basics',JSON.stringify(eventData))
              }} />
          </FormItem>
          <FormItem label="Unit#">
            <Input placeholder="" defaultValue={eventData.address.unit}
            onChange={(e)=>{
              const unit = e.target.value
              setEventData({...eventData, address: {...eventData.address, unit: unit}})
              localStorage.setItem('create_event_basics',JSON.stringify(eventData))
            }} />
          </FormItem>
          <FormItem label="City">
            <Input placeholder="" defaultValue={eventData.address.city} 
            onChange={(e)=>{
              const city = e.target.value
              setEventData({...eventData, address: {...eventData.address, city: city}})
              localStorage.setItem('create_event_basics',JSON.stringify(eventData))
            }} />
          </FormItem>
          <FormItem label="State/Province">
            <Input placeholder="" defaultValue={eventData.address.state} 
            onChange={(e)=>{
              const state = e.target.value
              setEventData({...eventData, address: {...eventData.address, state: state}})
              localStorage.setItem('create_event_basics',JSON.stringify(eventData))
            }} />
          </FormItem>
          <FormItem label="Postal code / Zip code">
            <Input placeholder="" defaultValue={eventData.address.postalCode} 
            onChange={(e)=>{
              const postal = e.target.value
              setEventData({...eventData, address: {...eventData.address, postalCode: postal}})
              localStorage.setItem('create_event_basics',JSON.stringify(eventData))
            }} />
          </FormItem>
          <FormItem label="Place name">
            <Input placeholder="" defaultValue={eventData.address.name}
            onChange={(e)=>{
              const addrName = e.target.value
              setEventData({...eventData, address: {...eventData.address, name: addrName}})
              localStorage.setItem('create_event_basics',JSON.stringify(eventData))
            }} />
          </FormItem>

          <div className="mt-4">
            <ButtonSecondary onClick={()=>{
              let detailedAddr = eventData.address.street + ", " + eventData.address.city + ", " + eventData.address.state + " " + eventData.address.country
              geoCodeAddress(detailedAddr)
            }}>Validate Address</ButtonSecondary>
          </div>

          <div>
            <Label>Detailed address</Label>
            <span className="block mt-1 text-sm text-neutral-500 dark:text-neutral-400">
              {eventData.address.fullAddress}
            </span>
            <div className="mt-4">
              <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
              <div className="rounded-xl overflow-hidden">
                  <Map
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    defaultZoom={15}
                    defaultCenter={{
                      lat: eventData.address.lat,
                      lng: eventData.address.lng,
                    }}
                    gestureHandling={"greedy"}
                  >
                    <Marker
                      position={{ lat: eventData.address.lat, lng: eventData.address.lng }}
                      draggable
                      onDragEnd={(e) => console.log(e)}
                    />
                  </Map>
                </div>
            </div>
                </div>
                </div>
        </div>


        <div className="flex justify-between">
          <div>
            <h2 className="text-2xl font-semibold">Cover Image</h2>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          </div>


          <div>
        <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                    >
                      <span>Upload</span>
                      
                      <ModalCropImages
              renderChildren={({ openModal }) => (
                <div>
                  <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        className="sr-only"
                        onChange={(e)=>{
                          const files = e.currentTarget.files
                            if (files){
                              const imageURL = URL.createObjectURL(files[0])
                              localStorage.setItem("photo_temp",imageURL)
                              openModal()
                            }
                          }}
                      />
                </div>
              )}
            />
                    </label>      
        </div></div>
          
        </div>

        
        
        <div>
            <div className="mt-5 ">
              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
                <img src={eventData.photoURL} width="100%" height="350px" />
                {/* <div className="space-y-1 text-center">
                  <svg
                    className="mx-auto h-12 w-12 text-neutral-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  
                  <p className="text-xs text-neutral-500 dark:text-neutral-400">
                    PNG, JPG, GIF up to 10MB
                  </p>
                </div> */}
              </div>
            </div>
          </div>


      </>
    </CommonLayout>
  );
};

export default PageCreateEvent01;
