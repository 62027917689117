import React, { FC, useState, useEffect } from "react";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import DatePicker from "react-datepicker";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Checkbox from "@mui/material/Checkbox";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { numberLiteralTypeAnnotation } from "@babel/types";
import { NewEventAgendaType, NewEventTicketType } from "data/types";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { DataGrid, GridCellParams } from "@mui/x-data-grid";
import { uuidv4 } from "@firebase/util";

export interface PageCreateEventProps {}

const PageCreateEvent03: FC<PageCreateEventProps> = () => {

  const [agendaOpen, setAgendaOpen] = useState(false)
  const toggleAgendaDrawer = (newOpen: boolean) => () => {
    setAgendaOpen(newOpen)
  }

  const [agendas, setAgendas] = useState<NewEventAgendaType[]>([])
  const [agendaItem, setAgendaItem] = useState<NewEventAgendaType>({
    order: 0,
  location: '',
  endDate: '',
  startDate: '',
  description: '',
  name: '',
  id: uuidv4(),
  })
  async function resetAgendaItem(){
    setAgendaItem(
    {order: 0,
    location: '',
    endDate: '',
    startDate: '',
    description: '',
    name: '',
    id: uuidv4(),
    }
    )
  }

  const removeAgendaItem = (agendaId: string) => {
    let newAgendas = agendas.filter((agendaItm) => {
      return agendaItm.id != agendaId
    })
    var returnAgenda: NewEventAgendaType[] = []
    newAgendas.forEach((agendItm,index) => {
      let nAgendItm = agendItm
      nAgendItm.order = index
      returnAgenda.push(nAgendItm)
    })
    return returnAgenda
  }

  useEffect(()=>{
    const eventAgendaItem = localStorage.getItem('create_event_agendas') || '{}'
    if (eventAgendaItem != '{}'){
      let eventAgendaD = JSON.parse(eventAgendaItem) || {agendas: []}
      setAgendas(eventAgendaD.agendas)
      console.log(`Loaded agenda: ${agendas.length}`)
    }
  },[])

  const AgendaDrawerList = (
    <Box 
      role="presentation" 
      style={{width: '100%'}}
      >
         <div className="relative space-y-8" style={{margin: '16px'}}>
         <FormItem
            label="Name *"
            desc=""
          >
            <Input type="text" defaultValue={agendaItem.name} onChange={(e)=>{
              const changed = e.target.value
              setAgendaItem({...agendaItem, name: changed})
            }}/>
            
      </FormItem>

      <FormItem
            label="Description *"
            desc=""
          >
            <Textarea rows={5} defaultValue={agendaItem.description} onChange={(e)=>{
              const changed = e.target.value
              setAgendaItem({...agendaItem, description: changed})
            }}/>
            
      </FormItem>

      <FormItem
            label="Start Date *"
            desc=""
          >
            <Input type="datetime-local" defaultValue={agendaItem.startDate} onChange={(e)=>{
              const changed = e.target.value
              setAgendaItem({...agendaItem, startDate: changed})
            }}/>
            
      </FormItem>

      <FormItem
            label="End Date"
            desc=""
          >
            <Input type="datetime-local" defaultValue={agendaItem.endDate} onChange={(e)=>{
              const changed = e.target.value
              setAgendaItem({...agendaItem, endDate: changed})
            }}/>
      </FormItem>

      <FormItem
            label="Location *"
            desc=""
          >
            <Input type="text" defaultValue={agendaItem.location} onChange={(e)=>{
              const changed = e.target.value
              setAgendaItem({...agendaItem, location: changed})
            }}/>
            
      </FormItem>

           </div>

    

      

      



<div className="relative space-y-8" style={{margin: '16px'}}>
        <ButtonPrimary
        onClick={()=>{
          if (agendaItem.name != "" && 
              agendaItem.description != "" && 
              agendaItem.startDate != "" && 
              agendaItem.endDate != "" && 
              agendaItem.location != ""){
                var currentAgendas: NewEventAgendaType[] = []
              agendas.forEach((tik) => {
              currentAgendas.push(tik)
            })
                currentAgendas.push(agendaItem)
                setAgendas(currentAgendas)
                const jsonString = JSON.stringify({agendas: currentAgendas})
          localStorage.setItem('create_event_agendas',jsonString)
          resetAgendaItem()
          setAgendaOpen(false)

          }
          else{
            alert('Please enter the required field')
          }
          
        }}
        >Add</ButtonPrimary>
        <ButtonSecondary className="ml-3" onClick={()=>{
          setAgendaOpen(false)
        }}>Cancel</ButtonSecondary>
      </div>

      </Box>
  )

  

  return (
    <CommonLayout
      index="03"
      backtHref="/create-event-02"
      nextHref="/create-event-04"
    >
      <>
        <h2 className="text-2xl font-semibold">Agendas (Optional)</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
         
          <FormItem
            label="Agenda"
            desc="Entire place: Guests have the whole place to themselves—there's a private entrance and no shared spaces. A bedroom, bathroom, and kitchen are usually included."
          >
            <ButtonSecondary onClick={()=>{
              setAgendaOpen(true)
            }}>Add Agenda</ButtonSecondary>
            <Drawer open={agendaOpen} onClose={toggleAgendaDrawer(false)}>
              {AgendaDrawerList}
            </Drawer>
          </FormItem>

          <DataGrid  
            sx={{ overflowX: 'scroll' }}
            rows={agendas} 
            columns={[
              { field: "id", headerName: "Agenda ID" },
              { field: "name", headerName: "Name" },
              { field: "description", headerName: "Description" },
              { field: "startDate", headerName: "Start Date" },
              { field: "endDate", headerName: "End Date" },
              { field: "action", flex: 1, headerName: "Actions", renderCell: (params: GridCellParams) => {
                return <div>
                  {/* <a onClick={()=>{
                    setAgendaItem(params.row)
                    setAgendaOpen(true)
                  }} style={{color: 'blue'}}>{`Edit`}</a> */}
                  <a onClick={()=>{
                    const agendId = params.row.id as string
                    if (agendId != ""){
                      let newAgendas = removeAgendaItem(agendId)
                      setAgendas(newAgendas)
                      const jsonString = JSON.stringify({agendas: newAgendas})
                      localStorage.setItem('create_event_agendas',jsonString)
                    }
                    else{

                    }
                  }} style={{color: 'red'}}>{`Delete`}</a>
                  </div>;
              } },
            ]} />

        </div>




      </>
    </CommonLayout>
  );
};

export default PageCreateEvent03;
