import Label from "./../../components/Label/Label";
import React, { FC, useState, useEffect, Fragment } from "react";
import Avatar from "./../../shared/Avatar/Avatar";
import ButtonPrimary from "./../../shared/Button/ButtonPrimary";
import Input from "./../../shared/Input/Input";
import Select from "./../../shared/Select/Select";
import Textarea from "./../../shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet-async";
import Grid from '@mui/material/Grid2';
import { DataGrid } from "@mui/x-data-grid";
import { Tab } from "@headlessui/react";
import { Box, Paper, Button, IconButton, Typography, useTheme } from "@mui/material";
import { useParams } from "react-router";

export interface AccountPageProps {
  className?: string;
}

const EventMarketing: FC<AccountPageProps> = ({ className = "" }) => {

    const {id} = useParams()

    let [categories] = useState([
        "Overview",
        "Email Marketing",
        "Facebook Events",
        "Eventbrite Events"]);

  const dashboardsArray = [
    {
      id: '',
      title: 'Total Sales',
      increase: 0,
      total: 0,
      time: 'Today'
    },
    {
      id: '',
      title: 'Total Members',
      increase: 0,
      total: 0,
      time: 'Today'
    },
    {
      id: '',
      title: 'Total Emails',
      increase: 0,
      total: 0,
      time: 'Today'
    },
    {
      id: '',
      title: 'Total Orders',
      increase: 0,
      total: 0,
      time: 'Today'
    },
    {
      id: '',
      title: 'Total Tickets',
      increase: 0,
      total: 0,
      time: 'Today'
    },
    {
      id: '',
      title: 'Total Impressions',
      increase: 0,
      total: 0,
      time: 'Today'
    }
  ]

  const customerColumn = [
    { field: "id", flex: 1, headerName: "Email" },
    { field: "firstName", flex: 1, headerName: "First Name" },
    { field: "lastName", flex: 1, headerName: "Last Name" },
    { field: "tickets", flex: 1, headerName: "Tickets" },
    { field: "timestamp", flex: 1, headerName: "Timestamp" },
  ]

  const orderColumn = [
    { field: "id", flex: 1, headerName: "Order ID" },
    { field: "firstName", flex: 1, headerName: "First Name" },
    { field: "lastName", flex: 1, headerName: "Last Name" },
    { field: "tickets", flex: 1, headerName: "Tickets" },
    { field: "timestamp", flex: 1, headerName: "Timestamp" },
  ]

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event Organizer Dashboard</title>
      </Helmet>
      <CommonLayout eventId={id || ""} eventTitle="">
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Marketing</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <Tab.Group>
            <Tab.List className="flex space-x-1 overflow-x-auto">
              {categories.map((item) => (
                <Tab key={item} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                        selected
                          ? "bg-secondary-900 text-secondary-50 "
                          : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      } `}
                    >
                      {item}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
              <div className="space-y-6 sm:space-y-8">
                <h2 className="text-2xl font-semibold">Overview</h2>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
              </div>
              </Tab.Panel>
              <Tab.Panel>
              <div className="space-y-6 sm:space-y-8">
                <h2 className="text-2xl font-semibold">Email Marketing</h2>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
              </div>
              </Tab.Panel>
              <Tab.Panel>
              <div className="space-y-6 sm:space-y-8">
                <h2 className="text-2xl font-semibold">Facebook Events</h2>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
              </div>
              </Tab.Panel>
              <Tab.Panel>
              <div className="space-y-6 sm:space-y-8">
                <h2 className="text-2xl font-semibold">Eventbrite Events</h2>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
              </div>
              </Tab.Panel>
            </Tab.Panels>
            </Tab.Group>
        </div>
      </CommonLayout>
    </div>
  );
};

export default EventMarketing;
