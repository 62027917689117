import React from "react";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import ButtonSecondary from "shared/Button/ButtonSecondary";
import EventInfoDropdown from "./EventInfoDropdown";

export interface CommonLayoutProps {
  children?: React.ReactNode;
  eventId: string;
  eventTitle: string;
}

const CommonLayout: FC<CommonLayoutProps> = ({ children, eventId = "", eventTitle = "Event Name Here" }) => {
  return (
    <div className="nc-CommonLayoutProps bg-neutral-50 dark:bg-neutral-900">
      <div className="border-b border-neutral-200 dark:border-neutral-700 pt-12 bg-white dark:bg-neutral-800">
        <div className="container">
        <div className="flex justify-between items-center">
          <div>
          <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/account-organization">
          My Events
        </Link>
        <Typography sx={{ color: 'text.primary' }}>{eventTitle}</Typography>
      </Breadcrumbs>
          </div>
          <div>
            <EventInfoDropdown eventId={eventId} eventTitle={eventTitle}/>
          </div>
        </div>
        
         <h1 style={{fontSize: 20, fontWeight: 'bold'}}>{eventTitle}</h1>

          <div className="flex space-x-8 md:space-x-14 overflow-x-auto hiddenScrollbar">
            <NavLink
              to={"/manage-event/"+eventId}
              className={({ isActive }) =>
                `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                  !isActive ? "border-transparent" : "border-primary-500"
                }`
              }
            >
              Overview
            </NavLink>
            <NavLink
              to={"/manage-event-order/"+eventId}
              className={({ isActive }) =>
                `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                  !isActive ? "border-transparent" : "border-primary-500"
                }`
              }
            >
              Orders
            </NavLink>
            {/* <NavLink
              to={"/manage-event-marketing/"+eventId}
              className={({ isActive }) =>
                `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                  !isActive ? "border-transparent" : "border-primary-500"
                }`
              }
            >
              Marketing
            </NavLink> */}
            <NavLink
              to={"/manage-event-customer/"+eventId}
              className={({ isActive }) =>
                `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                  !isActive ? "border-transparent" : "border-primary-500"
                }`
              }
            >
              Attendees
            </NavLink>
            <NavLink
              to={"/manage-event-checkin/"+eventId}
              className={({ isActive }) =>
                `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                  !isActive ? "border-transparent" : "border-primary-500"
                }`
              }
            >
              Check-In
            </NavLink>
            <NavLink
              to={"/manage-event-analytics/"+eventId}
              className={({ isActive }) =>
                `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                  !isActive ? "border-transparent" : "border-primary-500"
                }`
              }
            >
              Data Analytics
            </NavLink>
            <NavLink
              to={"/manage-event-payouts/"+eventId}
              className={({ isActive }) =>
                `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                  !isActive ? "border-transparent" : "border-primary-500"
                }`
              }
            >
              Payouts
            </NavLink>
            <NavLink
              to={"/manage-event-settings/"+eventId}
              className={({ isActive }) =>
                `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                  !isActive ? "border-transparent" : "border-primary-500"
                }`
              }
            >
              Settings
            </NavLink>
          </div>
        </div>
      </div>
      <div className="container pt-14 sm:pt-20 pb-24 lg:pb-32">{children}</div>
    </div>
  );
};

export default CommonLayout;
