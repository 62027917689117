import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/travelhero2.png";
import GallerySlider from "components/GallerySlider/GallerySlider";
import Carousel from 'react-material-ui-carousel'
import HeroRealEstateSearchForm from "components/HeroSearchForm/(real-estate-search-form)/HeroRealEstateSearchForm";
import { Paper, Button } from '@mui/material'
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";

export interface SectionHero3Props {
  className?: string;
}


const SectionHero3: FC<SectionHero3Props> = ({ className = ""}) => {
  var items = [
    {
        name: "Random Name #2",
        description: "Hello World!",
        imageURL: imagePng,
    }
]
  return (
    <div
      className={`nc-SectionHero3 relative ${className}`}
      data-nc-id="SectionHero3"
    >
      
      {/* <div className="absolute z-10 inset-x-0 top-[10%] sm:top-[15%] text-center flex flex-col items-center max-w-2xl mx-auto space-y-4 lg:space-y-5 xl:space-y-8">
        <span className="sm:text-lg md:text-xl font-semibold text-neutral-900">
          Event Go
        </span>
        <h2 className="font-bold text-black text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl !leading-[115%] ">
          Welcome to Event Go
        </h2>
        <ButtonPrimary
          sizeClass="px-6 py-3 lg:px-8 lg:py-4 rounded-xl"
          fontSize="text-sm sm:text-base lg:text-lg font-medium"
        >
          Explore
        </ButtonPrimary>
      </div> */}

    {/* "relative aspect-w-1 aspect-h-1 sm:aspect-w-4 sm:aspect-h-3 lg:aspect-w-16 lg:aspect-h-9 xl:aspect-h-8"  */}
      <div>
        <Carousel className="absolute align-items-center">
            {
                items.map( (item, i) => <Paper className="d-flex align-items-center justify-content-center">
                  <img
          style={{objectFit: 'cover', objectPosition: 'center'}}
          src={item.imageURL}
          alt="eventgo image"
        />
                </Paper> )
            }
        </Carousel>
        <div className="hidden lg:block lg:mt-20 w-full">
          <HeroSearchForm/>
        </div>
      </div>
    </div>
  );
};

export default SectionHero3;
