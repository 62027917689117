import React, { FC, useEffect, useState } from "react";
import LocationInput from "../LocationInput";
import StayDatesRangeInput from "./StayDatesRangeInput";
import CategoryInput from "../CategoryInput";
import KeywordsInput from "../KeywordsInput";
import { useDispatch, useSelector } from "react-redux";
import { homeCityPanelLocation, selectLocation, setHomeFilter } from "store";
import EventSizeInput from "../EventSizeInput";

export interface HomeSearchFilterData{
  keywords?: string,
  startDate?: string,
  endDate?: string,
  location?: string
  size?: string
}

const StaySearchForm: FC = () => {

  const [searchInfo, setSearchInfo] = useState<HomeSearchFilterData>({})
  const citySelected = useSelector(homeCityPanelLocation)
  const [cityName, setCityName] = useState('')
  const dispatch = useDispatch()

  const handleSearchInfo = (value:HomeSearchFilterData)=>{
    console.log('handleSearchInfo ==> '+value.location)
    var curValue = {
      ...searchInfo,
      ...value
    }
    setSearchInfo(curValue)
  }

  useEffect(()=>{
    if(citySelected != null && citySelected.length > 0){
      setCityName(citySelected)
      dispatch(setHomeFilter({'filter':{'location':citySelected, 'startDate':'', 'endDate':'', 'size':''}}))
      dispatch(selectLocation(''))
    }
  }, [citySelected])

  const handleDoSearchEvent = ()=>{
    dispatch(setHomeFilter({'filter':searchInfo}))
  }

  const renderForm = () => {
    return (
      <form className=" =w-full relative mt-8 flex rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 ">
        <LocationInput choosedInfo={cityName === ''?null:cityName} className="flex-1" handleSearchInfo={handleSearchInfo} />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <EventSizeInput className="flex-1" handleSearchInfo={handleSearchInfo} />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <StayDatesRangeInput className="flex-1" handleSearchInfo={handleSearchInfo}/>
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <KeywordsInput className="flex-1" handleSearchInfo={handleSearchInfo} handleDoSearchEvent={handleDoSearchEvent}/>
      </form>
    );
  };

  return renderForm();
};

export default StaySearchForm;
