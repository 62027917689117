import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { EventDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";

export interface EventDataDisplayProps {
  className?: string;
  timestamp?: string;
  increase?: string;
  title?: string;

  size?: "default" | "small";
}

const DEMO_DATA = {
    id: '',
  title: '',
  description: '',
  startDateString: '',
  endDateString: '',
  address: '',
  photoURLs: [],
  photoURL: '',
  viewCount: 0,
  price: 0,
  location: '',
};

const EventDataDisplayCard: FC<EventDataDisplayProps> = ({
  size = "default",
  className = "",
  title = "",
  increase = "",
  timestamp = "",
}) => {
  

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <span className="text-1xl text-neutral-500 dark:text-neutral-400">
            {title}
          </span>
          <div style={{marginTop: 20}} className="flex items-center space-x-2">
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-lg" : "text-base"
              }`}
            >
              <span className="line-clamp-1 font-semibold text-4xl">{increase}</span>
            </h2>
          </div>
          <div className="mt-5">
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            <span>Last update: {timestamp}</span>
          </div>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {renderContent()}
    </div>
  );
};

export default EventDataDisplayCard;
