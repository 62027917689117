import rightImg from "images/about-hero-right.png";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet-async";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero3 from "components/SectionHero/SectionHero3";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About us || Event Go</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />
      {/* container py-16 lg:py-28 space-y-16 lg:space-y-28 */}
      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        {/* <SectionHero3 className="" /> */}
        <span></span>
        <SectionHero
          rightImg={rightImg}
          heading="LOVE YOUR EVENTS!"
          btnText=""
          subHeading="EventGo charts novel paths in the event industry, leveraging pioneering technology to enhance event visibility, streamline ticketing, and deliver impactful experiences affordably. Our data-driven approach sets trends for sustainable event management, elevating each gathering into a lasting memory. We're not just part of the event ecosystem; we're redefining it, merging strategic production with seamless execution."
        />

        <SectionStatistic />

        <SectionFounder />


        <div className="ncSectionLogos grid grid-cols-3 lg:grid-cols-6 gap-6 sm:gap-16">
        <div className="flex items-end justify-start">
            <img className="block dark:hidden" src={'/about/p_cniw.png'} alt="logo1" />
            <img className="hidden dark:block" src={'/about/p_cniw.png'} alt="logo1" />
          </div>
          <div className="flex items-end justify-start">
            <img className="block dark:hidden" src={'/about/p_xdl.png'} alt="logo4" />
            <img className="hidden dark:block" src={'/about/p_xdl.png'} alt="logo4" />
          </div>
          <div className="flex items-end justify-start">
            <img className="block dark:hidden" src={'/about/p_cuma.png'} alt="logo2" />
            <img className="hidden dark:block" src={'/about/p_cuma.png'} alt="logo2" />
          </div>
          <div className="flex items-end justify-start">
            <img className="block dark:hidden" src={'/about/p_fantuan.png'} alt="logo3" />
            <img className="hidden dark:block" src={'/about/p_fantuan.png'} alt="logo3" />
          </div>
          <div className="flex items-end justify-start">
            <img className="block dark:hidden" src={'/about/p_9000.png'} alt="logo5" />
            <img className="hidden dark:block" src={'/about/p_9000.png'} alt="logo5" />
          </div>
          <div className="flex items-end justify-start">
            <img className="block dark:hidden" src={'/about/p_gb.png'} alt="logo6" />
            <img className="hidden dark:block" src={'/about/p_gb.png'} alt="logo6" />
          </div>
        </div>

        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageAbout_" />
        </div>

        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default PageAbout;
