import React, { FC, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet-async";
import Iframe from 'react-iframe'
import ButtonSecondary from "shared/Button/ButtonSecondary";


export interface PageLoginProps {
  className?: string;
}





const PagePrivacyPolicy: FC<PageLoginProps> = ({ className = "" }) => {

    

    const renderContent = () => {
        return (
          <div
            id="single-entry-content"
            className="flex justify-center"
          >
              <ButtonSecondary href="/user-privacy.html">View Our Privacy Policy</ButtonSecondary>
                    
          </div>
        );
      };
  
  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Event Go | Privacy Policy</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Privacy Policy
        </h2>
        {renderContent()}
      </div>
    </div>
  );
};

export default PagePrivacyPolicy;
