import Label from "./../../components/Label/Label";
import React, { FC, useEffect, useState } from "react";
import Avatar from "./../../shared/Avatar/Avatar";
import ButtonPrimary from "./../../shared/Button/ButtonPrimary";
import Input from "./../../shared/Input/Input";
import Select from "./../../shared/Select/Select";
import Textarea from "./../../shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet-async";
import Grid from '@mui/material/Grid2';
import { useParams } from "react-router";
import { Box, Paper, Button, IconButton, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import useEventDashboardLoader from "hooks/useEventDashboardLoader";

export interface AccountPageProps {
  className?: string;
}

const EventTickets: FC<AccountPageProps> = ({ className = "" }) => {

    const {id} = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const ticketColumn = [
      { field: "ticketId", flex: 1, headerName: "Ticket ID" },
      { field: "userName", flex: 1, headerName: "Name" },
      { field: "ticketName", flex: 1, headerName: "Ticket" },
      { field: "status", flex: 1, headerName: "Status" },
      { field: "timestamp", flex: 1, headerName: "Timestamp" },
    ]
    
    const eventData = useEventDashboardLoader({ eventId: id || "", isLoading: isLoading, mode: 'tickets', handleCompleteCallback: ()=>{
      
    }})

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event Organizer Dashboard</title>
      </Helmet>
      <CommonLayout eventId={id || ""} eventTitle={eventData.eventItem.title}>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Tickets ({eventData.tickets.length})</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <DataGrid 
            checkboxSelection 
            sx={{ overflowX: 'scroll' }}
        rows={eventData.tickets} 
        columns={ticketColumn} />
        </div>
      </CommonLayout>
    </div>
  );
};

export default EventTickets;
