import React, { FC } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import NavigationItem from "shared/Navigation/NavigationItem";
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";

export interface EventGoUserNavProps {
  className?: string;
}

const EventGoUserNav: FC<EventGoUserNavProps> = ({ className = "" }) => {
  return (
    <div className={`nc-MainNav1 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
        <div className="hidden md:flex justify-start flex-1 items-center space-x-4 sm:space-x-10">
          <Logo />
          <Navigation />
        </div>

        <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">
          <HeroSearchForm2MobileFactory />
        </div>

        <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="hidden xl:flex items-center space-x-0.5">
            <SwitchDarkMode />
            <SearchDropdown />
            <div className="px-1" />
            <ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
            <NavigationItem key={"account-key"} menuItem={
              {
                id: "mainmenu-actions",
                href: (localStorage.getItem("user_uid") != null && localStorage.getItem("user_uid") != "") ? "/account" : "/login",
                name: (localStorage.getItem("user_uid") != null && localStorage.getItem("user_uid") != "") ? "Account" : "Log In",
                type: (localStorage.getItem("user_uid") != null && localStorage.getItem("user_uid") != "") ? "dropdown" : "none",
                children: (localStorage.getItem("user_uid") != null && localStorage.getItem("user_uid") != "") ? [
                    {
                      id: 'account-dashboard',
                      href: "/dashboard",
                      name: "Manage Event",
                    },
                    {
                      id: 'account-userpass',
                      href: '/account',
                      name: 'My Events'
                    }
                    ] : []
              }} />
            </ul>
            {/* <ButtonPrimary href={(localStorage.getItem("user_uid") != null && localStorage.getItem("user_uid") != "") ? "/account" : "/login"}>{(localStorage.getItem("user_uid") != null && localStorage.getItem("user_uid") != "") ? "Account" : "Log In"}</ButtonPrimary> */}
          </div>
          <div className="flex xl:hidden items-center">
            <SwitchDarkMode />
            <div className="px-0.5" />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventGoUserNav;
