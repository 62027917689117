import React, { FC, useEffect, useState } from "react";
import LocationInput from "../LocationInput";
import DatesRangeInput from "../DatesRangeInput";
import { GuestsObject } from "components/HeroSearchForm/type";
import converSelectedDateToString from "utils/converSelectedDateToString";
import EventSizeInput from "../EventSizeInput";
import { start } from "repl";
import { format } from "date-fns";
import { homeSearchFilter } from "store";
import { useSelector } from "react-redux";


export interface StaySearchFormProps {
  onDataChanged?:any;
}

const StaySearchForm: FC<StaySearchFormProps> = ({
  onDataChanged = null,
}) => {
  //
  const [fieldNameShow, setFieldNameShow] = useState<
    "location" | "dates" | "size" | ""
  >("location");

  const storeInfo:any = useSelector(homeSearchFilter)
  //
  const [locationInputTo, setLocationInputTo] = useState(storeInfo.filter['location']);
  const [sizeChooseTo, setSizeChooseTo] = useState(storeInfo.filter['size']?[
    "1-10",
    "10-50",
    "50-100",
    "100-200",
    "200-500",
    "500-1000",
    "1000-2000",
    "2000-5000",
    "5000-10000",
    ][Number(storeInfo.filter['size'])]:'');
  const [startDate, setStartDate] = useState<Date | null>(storeInfo.filter['startDate'].length > 0 ?  new Date(storeInfo.filter['startDate']):null);
  const [endDate, setEndDate] = useState<Date | null>(storeInfo.filter['endDate'].length > 0 ?  new Date(storeInfo.filter['endDate']):null);
  

  useEffect(()=>{

    var searchInfo:any = {}
    if(startDate && endDate){
      searchInfo['startDate'] = format(startDate, 'yyyy-MM-dd');
      searchInfo['endDate'] = format(endDate, 'yyyy-MM-dd');
    }

    if(locationInputTo.length > 0){
      searchInfo['location'] = locationInputTo;
    }

    if(sizeChooseTo.length >0){
      searchInfo['size'] = ''+[
        "1-10",
        "10-50",
        "50-100",
        "100-200",
        "200-500",
        "500-1000",
        "1000-2000",
        "2000-5000",
        "5000-10000",
        ].indexOf(sizeChooseTo)
    }
    onDataChanged && onDataChanged(JSON.parse(JSON.stringify(searchInfo)))
  }, [startDate, endDate, locationInputTo, sizeChooseTo])

  const renderInputLocation = () => {
    const isActive = fieldNameShow === "location";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("location")}
          >
            <span className="text-neutral-400">Where</span>
            <span>{locationInputTo || "Location"}</span>
          </button>
        ) : (
          <LocationInput
            defaultValue={locationInputTo}
            onChange={(value) => {
              setLocationInputTo(value);
              setFieldNameShow("size");
            }}
          />
        )}
      </div>
    );
  };

  const renderInputDates = () => {
    const isActive = fieldNameShow === "dates";

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4  `}
            onClick={() => setFieldNameShow("dates")}
          >
            <span className="text-neutral-400">When</span>
            <span>
              {startDate
                ? converSelectedDateToString([startDate, endDate])
                : "Add date"}
            </span>
          </button>
        ) : (
          <DatesRangeInput onDateChange={(start:any, end:any)=>{
            setStartDate(start)
            setEndDate(end)
            setFieldNameShow("");
          }}/>
        )}
      </div>
    );
  };

  const renderSizeChoose = () => {
    const isActive = fieldNameShow === "size";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("size")}
          >
            <span className="text-neutral-400">Event Size</span>
            <span>{sizeChooseTo || `Choose Size`}</span>
          </button>
        ) : (
          <EventSizeInput 
          onChange={(value) => {
            setSizeChooseTo(value);
            setFieldNameShow("dates");
          }}
          />
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="w-full space-y-5">
        {renderInputLocation()}
        {renderSizeChoose()}
        {renderInputDates()}
      </div>
    </div>
  );
};

export default StaySearchForm;
