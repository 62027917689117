import React, { FC, useEffect, useRef, useState } from "react";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import DatePicker from "react-datepicker";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Label from "components/Label/Label";
import Avatar from "shared/Avatar/Avatar";
import {auth, fs} from "firebaseSetup";
import {Checkbox} from "@mui/material"
import { getDoc, getDocs, collection, doc, setDoc, Timestamp } from "firebase/firestore"
import { Email } from "@mui/icons-material";
import { uuidv4 } from "@firebase/util";

export interface PageCreateEventProps {}

const PageCreateEvent05: FC<PageCreateEventProps> = () => {

  const [profileExist, setProfileExist] = useState(false)
  const [checkTerm, setCheckTerm] = useState(false);
  const default_photo = "https://media.istockphoto.com/id/1495088043/vector/user-profile-icon-avatar-or-person-icon-profile-picture-portrait-symbol-default-portrait.jpg?s=612x612&w=0&k=20&c=dhV2p1JwmloBTOaGAtaA3AW1KSnjsdMt7-U_3EZElZ0="
  const [orgInfo, setOrgInfo] = useState({
    name: '',
    description: '',
    imageURL: default_photo,
    orgId: '',
    newOrg: true,
    emtEmail: '',
  })

  const [orgError, setOrgError] = useState({
    name: '',
    description: '',
    imageURL: '',
    orgId: '',
    emtEmail: '',
  })

  async function checkIfOrgIdExisted(){
    if (orgInfo.orgId != ""){
      let groupRef = doc(fs,"groups",orgInfo.orgId)
      const existed = (await getDoc(groupRef)).exists()
      return existed
    }
    else{
      return true
    }
  }

  async function uploadImage(file:File) {
    fetch('https://r2-worker.sam-12e.workers.dev/', {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': file.type
      },
      body: file
    })
      .then((response) => response.text())
      .then((data) => {
        const downloadURL = "https://pub-e888c0363b7c412caf77f22ba84fcb86.r2.dev/" + data
        let orgInfoo = orgInfo
        orgInfoo.imageURL = downloadURL
        setOrgInfo({...orgInfo, imageURL: downloadURL})
        
      }) // 
      .catch((error) => console.error(error));
  }

  useEffect(()=>{

    //get user information
    let userOrgId = ""
    if (auth.currentUser != null){
      const userUID = auth.currentUser?.uid
      let doccRef = doc(fs,"users",userUID)
        getDoc(doccRef).then((snapshot) => {
          if (snapshot.exists()){
            let userD = snapshot.data()
            if (userD.orgId != null){
              if (userD.orgId != ""){
                //has org
                userOrgId = userD.orgId
              }
            }
            if (userOrgId != ""){
              let groupRef = doc(fs,"groups",userOrgId)
              getDoc(groupRef).then((groupSnap) => {
                if (groupSnap.exists()){
                  const groupD = groupSnap.data()
                  let orgExt = {
                    name: groupD.name,
                    description: groupD.description,
                    imageURL: groupD.profileURL,
                    orgId: userOrgId,
                    newOrg: false,
                    emtEmail: groupD.info.email,
                  }
                  setOrgInfo(orgExt)
                  localStorage.setItem('create_event_orgs', JSON.stringify(orgExt))
                  setProfileExist(true)
                }
              })
            }
            else{
              let eventOrgItem = localStorage.getItem('create_event_orgs') || '{}'
              if (eventOrgItem != '{}'){
                let eventOrgD = JSON.parse(eventOrgItem)
                setOrgInfo(eventOrgD)
              }
            }
          }
        })
    }
    

  },[])


  const existingProfile = () => {
    return (
      <div className="space-y-8">
        <div className="flex flex-col md:flex-row">
            <div className="flex-shrink-0 flex items-start">
              <div className="relative rounded-full overflow-hidden flex">
                <Avatar sizeClass="w-32 h-32" imgUrl={orgInfo.imageURL} />
                <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                      stroke="currentColor"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
          <FormItem label="Organization Name" desc="" >
            <Label className="text-2xl font-semibold">{orgInfo.name}</Label>      
          </FormItem>
          <FormItem label="Organization Description" desc="" >
            <Label className="text-2xl font-semibold">{orgInfo.description}</Label>      
          </FormItem>
          <FormItem label="Organization ID" desc="" >
            <Label className="text-2xl font-semibold">{orgInfo.orgId}</Label>      
          </FormItem>
          <FormItem label="Email Transfer" desc="" >
            <Label className="text-2xl font-semibold">{orgInfo.emtEmail}</Label>      
          </FormItem>
          </div>
          </div>
          

      </div>
    )
  }

  const newProfile = () => {
    return (
      <div className="space-y-8">
          {/* ITEM */}
          <div className="flex flex-col md:flex-row">
            <div className="flex-shrink-0 flex items-start">
              <div className="relative rounded-full overflow-hidden flex">
                <Avatar sizeClass="w-32 h-32" imgUrl={orgInfo.imageURL} />
                <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                      stroke="currentColor"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="mt-1 text-xs">Change Image</span>
                </div>
                <input
                  type="file"
                  onChange={(e)=>{
                    const files = e.currentTarget.files
                    if (files){
                     uploadImage(files[0])
                    }  
                  }}
                  className="absolute inset-0 opacity-0 cursor-pointer"
                />
              </div>
            </div>
            <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">

              <FormItem label="Organization Name *" desc="" >
                <Input type="text" defaultValue={orgInfo.name} onChange={(e)=>{
                  const valu = e.target.value
                  setOrgInfo({...orgInfo, name: valu})
                  localStorage.setItem('create_event_orgs',JSON.stringify(orgInfo))
                }}/>
              </FormItem>

              <FormItem label="EventGo Organization ID *" desc={orgError.orgId} >
                <Input type="text" value={orgInfo.orgId} defaultValue={orgInfo.orgId} onChange={(e)=>{
                  const valu = e.target.value.replace(" ","-")
                  setOrgInfo({...orgInfo, orgId: valu})
                  localStorage.setItem('create_event_orgs',JSON.stringify(orgInfo))
                }}/>
                <div className="mt-3">
                  <ButtonPrimary onClick={()=>{
                    setOrgInfo({...orgInfo, orgId: uuidv4()})
                  }}>Auto-Generate</ButtonPrimary>
                </div>
                
              </FormItem>

              <FormItem label="EMT Emails *" desc={orgError.emtEmail} >
                <Input type="email" defaultValue={orgInfo.emtEmail} onChange={(e)=>{
                  const valu = e.target.value
                  setOrgInfo({...orgInfo, emtEmail: valu})
                  localStorage.setItem('create_event_orgs',JSON.stringify(orgInfo))
                  if (!valu.includes('@')){
                    setOrgError({...orgError, emtEmail: 'Please enter valid email'})
                  }
                  else{
                    setOrgError({...orgError, emtEmail: ''})
                  }
                }}/>
              </FormItem>

              <FormItem>
              <Checkbox defaultChecked={checkTerm} onChange={(e)=>{
                setCheckTerm(true)
              }} />
              <a>
                <span style={{}}>You agree with our <a href="/org-terms.html">Terms of Organizer</a> and <a href="/user-privacy.html">Privacy Policy</a></span>
              </a>
              </FormItem>
             
              <div className="pt-2">
                <ButtonPrimary onClick={()=>{
                  checkIfOrgIdExisted().then((orgExist) => {
                    if (!orgExist && orgInfo.orgId != "" && orgInfo.emtEmail != "" && orgInfo.name != ""){
                      localStorage.setItem('create_event_orgs', JSON.stringify(orgInfo)) 
                      window.location.href = "/create-event-preview"
                    }
                    else {
                      if (orgExist){
                        alert("Organization ID not available")
                      }
                      if (orgInfo.emtEmail == ""){
                        alert("Please enter emt emails")
                      }
                    }

                  })
                  
                }}>Save</ButtonPrimary>
              </div>
            </div>
          </div>

        </div>
    )
  }


  return (
    <CommonLayout
      index="05"
      backtHref="/create-event-04"
      nextHref="/create-event-preview"
    >
      <>
        <h2 className="text-2xl font-semibold">Your Organization</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        {
          profileExist ? existingProfile() : newProfile()
        }
        


      </>
    </CommonLayout>
  );
};

export default PageCreateEvent05;
