import React, { FC, ReactNode, useEffect, useState } from "react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { EventDataType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilter from "./HeaderFilter";
import EventCard from "components/EventCard/EventCard";
import StayCard from "components/StayCard/StayCard";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearHomeFilter, homeEvents, homeSearchFilter, setHomeFilter, updateEvents } from "store";

// OTHER DEMO WILL PASS PROPS


//
export interface SectionGridFeatureEventsProps {
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
  tabIds?: string[];
}

const SectionGridFeaturePlaces: FC<SectionGridFeatureEventsProps> = ({
  gridClass = "",
  heading = "Events now popular",
  subHeading = "Popular events are hosted now",
  headingIsCenter = false,
  tabs = [],
  tabIds = [],
}) => {
  const renderCard = (evt: EventDataType) => {
    return <EventCard key={evt.id} data={evt} />;
  };

  const eventListings = useSelector(homeEvents)
  const dispatch = useDispatch()
  const searchFilter = useSelector(homeSearchFilter)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selectedTab, setSelectedTab] = useState('For You')
  
  useEffect(()=>{
    if(searchFilter.valid){
      setSelectedTab(searchFilter['tagName'])    
      loadMoreListing(searchFilter['tag'], searchFilter['filter'])
    }
  }, [searchFilter])

  const filteredData = (data:any)=>{
    return Object.keys(data).length === 0 
    ? {} 
    : Object.fromEntries(
        Object.entries(data).filter(([key, value]) => value != null && value !== "")
      );
  }

  const loadMoreListing = (tag?:string, params?:{}) => {
    var url = 'https://us-central1-blink-574af.cloudfunctions.net/v1EventsAPIFetchRecommendedEvents'
    if((tag != null && tag !== 'all') || params != null){
      url += '?'
      const filterParams:{} = filteredData(params);
      var searchParams = new URLSearchParams(filterParams)
      if(tag != null && tag !== 'all' && tag.length > 0){
        searchParams.set('tag', tag)
      }
      url += searchParams.toString()
    }
    console.log('request=>'+url)
    fetch(url,{
         headers: {
           "Content-Type": "application/json",
         },
       }).then((response) => {
        
        const responseTask = response.json();
        responseTask.then((data) => {
          let dataItm = {data: [], count: 0}
          dataItm = data
          let newEventData: EventDataType[] = []
          dataItm.data.forEach((remoteItm: any) => {
              let eId = ""
              let eTitle = ""
              let eDescription = ""
              let ePhotoURL = ""
              let ePhotoURLs: string[] = []
              let eAddress = ""
              let eStartDateString = ""
              let eEndDateString = ""
              let ePrice = 0
             
              if (remoteItm.id != null){
                  eId = remoteItm.id
              }
              if (remoteItm.title != null){
                  eTitle = remoteItm.title
              }
              if (remoteItm.description != null){
                  eDescription = remoteItm.description
              }
              if (remoteItm.startDateString != null){
                  eStartDateString = remoteItm.startDateString
              }
              if (remoteItm.endDateString != null){
                  eEndDateString = remoteItm.endDateString
              }
              if (remoteItm.photoURL != null){
                  ePhotoURL = remoteItm.photoURL
              }
              if (remoteItm.photoURLs != null){
                  ePhotoURLs = remoteItm.photoURLs
                  if (ePhotoURLs.length === 0){
                    ePhotoURLs = [ePhotoURL]
                  }
              }
              else{
                  ePhotoURLs = [ePhotoURL]
              }
              if (remoteItm.price != null){
                  ePrice = remoteItm.price
              }
              if (remoteItm.address != null){
                  if (remoteItm.address.fullAddress != null){
                      eAddress = remoteItm.address.fullAddress
                  }
              }
              let tags: string[] = []
              if (remoteItm.tags != null){
                tags = remoteItm.tags
              }
              let eventDataItem: EventDataType = {
                id: eId,
                title: eTitle,
                description: eDescription,
                startDateString: eStartDateString,
                endDateString: eEndDateString,
                photoURLs: ePhotoURLs,
                photoURL: ePhotoURL,
                location: remoteItm.location,
                viewCount: 0,
                address: eAddress,
                price: ePrice,
                tags: tags,
                priceDiscount: remoteItm.priceDiscount
              }
              newEventData.push(eventDataItem)
          })
          
          dispatch(updateEvents(newEventData))
          setIsLoading(false)          
        })
      })
  }

  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      <HeaderFilter
        tabActive={selectedTab}
        subHeading={subHeading}
        tabs={tabs}
        heading={heading}
        onClickTab={(tab, name)=>{
          dispatch(setHomeFilter({'tag':tab, 'name':name}))
          setSelectedTab(name)
        }}
      />
      <div
        className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
      >
        {eventListings.map((event:any) => renderCard(event))}
      </div>
      <div className="flex mt-16 justify-center items-center">
        <ButtonPrimary loading={isLoading} onClick={()=>{
          if(selectedTab !== 'For You'){
            dispatch(clearHomeFilter())
            setSelectedTab('For You')
          }
        }}>Show me more</ButtonPrimary>
      </div>
    </div>
  );
};

export default SectionGridFeaturePlaces;
