import CardCategoryBox1 from "components/CardCategoryBox1/CardCategoryBox1";
import CardOrganizerBox from "components/CardOrganizerBox/CardOrganizerBox";
import Heading from "components/Heading/Heading";
import { TaxonomyType, OrganizerDisplayType } from "data/types";
import React from "react";
import HeaderFilter from "containers/PageHome/HeaderFilter";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface SectionGridOrganizerProps {
  organizers?: OrganizerDisplayType[];
  headingCenter?: boolean;
  className?: string;
  gridClassName?: string;
}


const SectionGridOrganizerBox: React.FC<SectionGridOrganizerProps> = ({
  organizers = [],
  headingCenter = false,
  className = "",
  gridClassName = "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3",
}) => {
  return (
    <div className={`nc-SectionGridCategoryBox relative ${className}`}>
      <HeaderFilter
        tabActive={""}
        subHeading={"See who is managing events on Event Go"}
        tabs={[]}
        heading={"Organizer"}
        onClickTab={() => {}}
      />
      <div className={`grid ${gridClassName} gap-5 sm:gap-6 md:gap-8`}>
        {organizers.map((item, i) => (
          <CardOrganizerBox key={i} organizer={item}/>
        ))}
      </div>
      <div className="mt-16 flex flex-col sm:flex-row justify-center space-y-3 sm:space-y-0 sm:space-x-5">
        <ButtonSecondary onClick={()=>{
          window.location.reload()
        }}>Show me more </ButtonSecondary>
        <ButtonPrimary href="/account-organization">Become Organizer</ButtonPrimary>
      </div>
    </div>
  );
};

export default SectionGridOrganizerBox;
