import Label from "../../components/Label/Label";
import React, { FC, useEffect, useState } from "react";
import Avatar from "../../shared/Avatar/Avatar";
import ButtonPrimary from "../../shared/Button/ButtonPrimary";
import Input from "../../shared/Input/Input";
import Select from "../../shared/Select/Select";
import Textarea from "../../shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet-async";
import Grid from '@mui/material/Grid2';
import { useParams } from "react-router";
import { Box, Paper, Button, IconButton, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import useEventDashboardLoader from "hooks/useEventDashboardLoader";
import Heading from "components/Heading/Heading";
import WidgetHeading1 from "containers/BlogPage/WidgetHeading1";
import CardCategory1 from "components/CardCategory1/CardCategory1";
import { DEMO_CATEGORIES } from "data/taxonomies";
import { TaxonomyType } from "data/types";
import EventCheckInUserCard from "components/EventManagementCard/EventCheckInUserCard";
import { onSnapshot, getDocs, doc, collection, query, where, orderBy, DocumentData } from "firebase/firestore";
import { fs } from "firebaseSetup";

export interface AccountPageProps {
  className?: string;
}

const EventCheckIn: FC<AccountPageProps> = ({ className = "" }) => {

    const {id} = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const [allMembers, setAllMembers] = useState<DocumentData[]>([])
    const [checkinMember, setCheckinMembers] = useState<DocumentData[]>([])

    useEffect(()=>{
      //load all users
      loadCheckInEventUsers()
    },[])

    async function loadCheckInEventUsers(){
      let eventId = id || ""
      if (eventId != ""){
        //get
        const memberRef = query(collection(fs, "events",eventId,'members'), where('checkinStatus','==', true), orderBy('timestamp','desc'));
        
        getDocs(memberRef).then((snapshot) => {
          let members: DocumentData[] = []
          snapshot.docs.forEach((doc) => {
            members.push(doc.data())
          })
          setAllMembers(members)
        })
      }
    }

    async function loadNotCheckInEventUsers(){
      let eventId = id || ""
      if (eventId != ""){
        //get
        const memberRef = query(collection(fs, "events",eventId,'members'), orderBy('timestamp','desc'));
        
        getDocs(memberRef).then((snapshot) => {
          let members: DocumentData[] = []
          snapshot.docs.forEach((doc) => {
            members.push(doc.data())
          })
          setAllMembers(members)
        })
      }
    }

    return (
      <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event Organizer Dashboard</title>
      </Helmet>
      <CommonLayout eventId={id || ""} eventTitle="">
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <div className="flex justify-between items-center">
            <h2 className="text-3xl font-semibold">Members Not Yet Checkin ({allMembers.length})</h2>
            <ButtonPrimary>Scan</ButtonPrimary>
          </div>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {
            allMembers.map((docData) => {
              return <EventCheckInUserCard name={docData.name} checkinStatus={docData.checkinStatus} ticketId={docData.ticketId} tickets={docData.tickets}/>
            })
          }
          </div>
          <div className="flex justify-between items-center">
            <h2 className="text-3xl font-semibold">Members Already Checkin ({allMembers.length})</h2>
          </div>
          <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {
            allMembers.map((docData) => {
              return <EventCheckInUserCard name={docData.name} checkinStatus={docData.checkinStatus} ticketId={docData.ticketId} tickets={docData.tickets}/>
            })
          }
          </div>
        </div>
      </CommonLayout>
    </div>
    )
};

export default EventCheckIn;
